import styled from 'styled-components';

export const Container = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.primary};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 10px;

  > img {
    margin-bottom: 40px;
  }

  > .title {
    margin-bottom: 40px;

    font-size: 35px;
    color: white;
  }

  > .question {
    margin: 24px 0 13px;

    color: white;
  }

  > .teste {
    display: flex;
    justify-content: space-between;
    width: 300px;
    position: absolute;
    top: 30px;
  }

  .logo {
    width: auto;
    height: 100px;
  }
`;
