import React, { createContext, useState } from 'react';

import { NAlert } from '~/components';
import { setStatus } from '~/repositories/status';

const AlertContext = createContext({});

const AlertProvider = ({ children }) => {
  const [error, setError] = useState({});
  const [type, setType] = useState('');
  const [success, setSuccess] = useState({});

  const newErrorAlert = (data, ty) => {
    if (typeof data === 'string') {
      setError({
        message: data,
      });
      setType(ty);

      return;
    }

    setError(data);
  };

  const newSuccessAlert = data => {
    if (typeof data === 'string') {
      setSuccess({
        message: data,
      });

      return;
    }

    setSuccess(data);
  };

  const confirmError = () => {
    if (type === 'socket') {
      setStatus('home');
      window.location.reload();
    }
    setError({});
    setType('');
  };

  return (
    <AlertContext.Provider value={{ newErrorAlert, newSuccessAlert }}>
      <NAlert
        visible={success.message}
        onClose={() => setSuccess({})}
        {...success}
        label={success.message}
      />
      <NAlert
        error
        visible={error.message}
        onClose={() => confirmError()}
        {...error}
        label={error.message}
      />
      {children}
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider };
