/* eslint-disable prettier/prettier */
/* eslint-disable no-alert */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  // NModalConfirm,
  Background,
  NCardHistoryDriver,
  // NSearch,
  NCardTrip,
  NTitle,
  Sidebar,
  TripPopup,
} from '~/components';
import { useApi, useApiEffect, useSocketContext } from '~/hooks';

import { getRoutes } from '~/services/routes';

import { getAppId } from '~/repositories/app';
import { getAccountId, getAuth } from '~/repositories/auth';

import { MdContentCopy } from 'react-icons/md';
import Wallet from '~/assets/icons/wallet.svg';
import { api } from '~/services/api';
import { getDriverGains, getTripsHistory } from '~/services/drivers';
import { getDomain } from '~/utils';
import { copyText } from '~/utils/copyText';
import { getCorrectDate } from '~/utils/correctTime';
import { Container, DriverBody, DriverId, GainsContainer } from './styles';

const Home = () => {
  const { isPassenger } = getAuth();

  const [driverLat, setDriverLat] = useState('');
  const [driverLng, setDriverLng] = useState('');


  const [driverGains, setDriverGains] = useState();

  const [routes, setRoutes] = useState([]);
  const [routesList, setRoutesList] = useState([]);

  const [trips, setTrips] = useState([]);
  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(1);

  const [anyRouter, setAnyRouter] = useState(false);

  const [tripPopupVisible, setTripPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState({});

  const [accountId, setAccountId] = useState('');

  const { socket } = useSocketContext();
  const { request } = useApi();

  const navigate = useNavigate();

  const { state } = useLocation();

  function showLoc(position) {
    setDriverLat(position.coords.latitude)
    setDriverLng(position.coords.longitude);
  }

  function getLoc() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showLoc);
    } else {
      alert("Seu navegador não suporta esse recurso");
    }
  }

  useEffect(() => {
    if (routes.length < 1) setAnyRouter(true);
    else setAnyRouter(false);

    setRoutesList(routes);

    const id = getAppId();
    setAccountId(id);
  }, [routes]);

  useEffect(() => {
    socket.on('receive-trip', data => {
      getLoc();
      setPopupData(data);
      setTripPopupVisible(true);

      setTimeout(() => {
        setTripPopupVisible(false);
      }, 30 * 1000);
    });
  }, []);

  const attData = data => {
    setTrips(data.content);
    setPages(data.pages);
  };

  useApiEffect(getRoutes, response => setRoutes(response.data.content));
  useApiEffect(
    () => getTripsHistory(page),
    response => attData(response.data)
  );

  if (!isPassenger) {
    if (state?.tripUrl) {
      navigate(state.tripUrl);
    }

    useEffect(() => {
      const perm = window.Notification.permission;

      if (perm !== 'granted') {
        window.Notification.requestPermission().then(permission => {
          if (permission !== 'granted') return;

          navigator.serviceWorker.getRegistration().then(async registration => {
            let subscription = await registration.pushManager.getSubscription();

            if (subscription) {
              await api.post(
                `/drivers/${getAccountId()}/subscription`,
                subscription
              );
            } else {
              subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey:
                  'BLNJNutOeXurxZaGgHm8KxIK4QgGkZ9TfA4R3SAmXtlONoW5Nu_KgGcil4Ru2HCFUEGtLLoVi5-IUu6p8s6G1j0',
              });
              await api.post(
                `/drivers/${getAccountId()}/subscription`,
                subscription
              );
            }
          });
        });
      }
    }, []);

    useApiEffect(
      () => getDriverGains(),
      response => {
        setDriverGains(response.data);
      }
    );
  }

  function acceptTrip(id) {
    socket.emit('accept-trip', { id });
  }

  function refuseTrip(id) {
    setTripPopupVisible(false);
    socket.emit('refuse-trip', { id });
  }

  const changePage = pageNumber => {
    setPage(pageNumber);
    request(
      () => getTripsHistory(pageNumber),
      response => attData(response.data)
    );
  };

  const date = finishedAt => {
    if (finishedAt) {
      const { newData } = getCorrectDate(finishedAt);

      return newData;
    }
    return 'Viagem cancelada';
  };

  const time = finishedAt => {
    const { newTime } = getCorrectDate(finishedAt);

    return newTime;
  };

  const cardsRoutesPassenger = routesList.map(route => (
    <NCardTrip
      key={route.id}
      adress1={route.boardingDistrict.name}
      adress2={route.landingDistrict.name}
      isDriver={false}
      className="card"
      onClick={() => {
        navigate(`/${getDomain()}/embarque`, {
          state: {
            establishment: state?.establishment ?? false,
            passengerId: state?.passengerId ?? null,
            embarkation: route.boardingDistrict,
            disembarkation: route.landingDistrict,
            reversed: false,
            routeId: route.id,
          },
        });
      }}
    />
  ));

  const cardsTrips = trips.map((trip, i) => (
    <NCardHistoryDriver
      key={i}
      date={date(trip.finishedAt)}
      time={time(trip.finishedAt)}
      profit={trip.gains.profit}
      total={trip.gains.total}
      origin={trip.route.boardingDistrict}
      destiny={trip.route.landingDistrict}
      passengers={trip.passengers}
      className="card"
    />
  ));

  return (
    <Container>
      <Background />
      <div className="div_top">
        {isPassenger && <NTitle label="Rotas" color="white" />}
        {!isPassenger && <Sidebar />}
        {!isPassenger && <p className="title_logo">Online</p>}
      </div>

      {isPassenger && cardsRoutesPassenger}

      {anyRouter && isPassenger && (
        <p className="title_warning">
          Nenhuma rota disponível para esse destino
        </p>
      )}

      {/* Driver Screen */}

      {!isPassenger && (
        <DriverBody>
          <GainsContainer>
            <strong>Hoje</strong>
            <div className="row">
              <img src={Wallet} alt="wallet-icon" />
              {driverGains && <h1>{driverGains.gains.day.profit}</h1>}
            </div>
            <div className="divider" />
            <div className="row gains">
              <div className="column">
                <span>Esta semana</span>
                {driverGains && <h2>{driverGains.gains.week.profit}</h2>}
              </div>
              <div className="separator" />
              <div className="column">
                <span>Este mês</span>
                {driverGains && <h2>{driverGains.gains.month.profit}</h2>}
              </div>
            </div>
          </GainsContainer>

          <DriverId>
            <p>Seu id : {accountId}</p>
            <MdContentCopy size={24} onClick={() => copyText(accountId)} />
          </DriverId>

          {cardsTrips}

          {pages && pages.total > 1 && (
            <div className="div_next">
              {pages.current > 1 && (
                <button
                  onClick={() => changePage(page - 1)}
                  type="button"
                  className="advanced"
                >
                  {`<`}
                </button>
              )}
              {pages.current < pages.total && (
                <button
                  onClick={() => changePage(page + 1)}
                  type="button"
                  className="advanced"
                >
                  &gt;
                </button>
              )}
            </div>
          )}
        </DriverBody>
      )}

      {!isPassenger && tripPopupVisible && (
        <TripPopup
          trip={popupData}
          driverLat={driverLat}
          driverLng={driverLng}
          onAccept={() => acceptTrip(popupData.id)}
          onRefuse={() => refuseTrip(popupData.id)}
        />
      )}
    </Container>
  );
};

export default Home;
