import { object, string, ref } from 'yup';

export const fields = {
  name: {
    type: 'text',
    label: 'Nome:',
    placeholder: 'Insira o seu nome completo.',
  },
  cpf: {
    mask: '999.999.999-99',
    label: 'CPF:',
    placeholder: 'Insira o seu CPF',
  },
  password: {
    type: 'password',
    label: 'Senha:',
    placeholder: 'Insira a senha da sua conta.',
  },
  passwordMatch: {
    type: 'password',
    label: 'Confirmar Senha:',
    placeholder: 'Insira a senha novamente.',
  },
};

export const extraFields = {
  pix: {
    type: 'text',
    label: 'Chave Pix:',
    placeholder: 'Email, telefone(Só números) ou aleatória.',
  },
};

export const passengerSchema = object({
  name: string().required('Preencha esse campo!').min(4, 'Nome muito curto!'),
  cpf: string()
    .required('Preencha esse campo!')
    .matches(/\d{3}.\d{3}.\d{3}-\d{2}/, 'Adicione um CPF válido!'),
  password: string()
    .required('Preencha esse campo!')
    .min(8, 'Senha muito curta!')
    .matches(/[A-Z]/, 'Adicione uma letra maiúscula!')
    .matches(/[a-z]/, 'Adicione uma letra minúscula!')
    .matches(/[0-9]/, 'Adicione um número!'),
  passwordMatch: string()
    .required('Preencha esse campo!')
    .oneOf([ref('password'), null], 'As senhas são diferentes!'),
}).required();

export const driverSchema = object({
  name: string().required('Preencha esse campo!').min(4, 'Nome muito curto!'),
  cpf: string()
    .required('Preencha esse campo!')
    .matches(/\d{3}.\d{3}.\d{3}-\d{2}/, 'Adicione um CPF válido!'),
  password: string()
    .required('Preencha esse campo!')
    .min(8, 'Senha muito curta!')
    .matches(/[A-Z]/, 'Adicione uma letra maiúscula!')
    .matches(/[a-z]/, 'Adicione uma letra minúscula!')
    .matches(/[0-9]/, 'Adicione um número!'),
  passwordMatch: string()
    .required('Preencha esse campo!')
    .oneOf([ref('password'), null], 'As senhas são diferentes!'),
  pix: string().required('Preencha esse campo!'),
}).required();
