import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useAuth } from '~/hooks';
import { getIsPassenger } from '~/repositories/auth';
import storage from '~/repositories/storage';

import Auth from './auth';
import Driver from './driver';
import LoadingPage from './loading';
import Passenger from './passenger';

const Routes = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  const route = () => {
    if (window.location.href.includes('/desembarque/')) {
      const tripId = window.location.href.split('/desembarque/')[1];

      if (!user) {
        storage.setItem('desembarque', tripId);
        // eslint-disable-next-line prefer-destructuring
        window.location.href = window.location.href.split(/desembarque/)[0];
      }
    }

    if (user) {
      return getIsPassenger() ? Passenger : Driver;
    }

    return Auth;
  };

  const Route = route();

  return (
    <BrowserRouter>
      {loading && !user ? (
        <LoadingPage hideLoading={() => setLoading(false)} />
      ) : (
        <Route />
      )}
    </BrowserRouter>
  );
};

export default Routes;
