import React from 'react';

import { ReactComponent as Icon } from '~/assets/icons/flechas_icon.svg';

// prettier-ignore
import { Container } from './styles';
import NCardPeopleItem from '../NCardPeopleItem';

const NCardTrip = ({ adress1, adress2, price, time, isDriver, ...rest }) => (
  <Container {...rest}>
    {/* prettier-ignore */}
    <div className="nameContainer">
      <Icon className="icon" />
      <div>
        {adress1 && <p className="label">{adress1}</p>}
        {adress2 && <p className="label">{adress2}</p>}
      </div>
    </div>
    <NCardPeopleItem name={time} price="Iniciar rota" />
  </Container>
);

export default NCardTrip;
