export class Cpf {
  constructor(cpf) {
    this.cpf = cpf;
    if (!this.cpf) throw new Error('Cpf not found');
    if (!this.isValidLength()) throw new Error('Cpf not valid');

    this.cpf = this.formatCpf();
  }

  getCpf() {
    return this.cpf;
  }

  cpfValidator() {
    if (this.isInvalidFormat()) return false;

    try {
      let cpfSumFirstCharacter = 0;
      let cpfSumSecondCharacter = 0;
      for (let digit = 1; digit < this.cpf.length - 1; digit += 1) {
        const singleCpfBaseDigit = Number(this.cpf.substring(digit - 1, digit));
        cpfSumFirstCharacter += (11 - digit) * singleCpfBaseDigit;
        cpfSumSecondCharacter += (12 - digit) * singleCpfBaseDigit;
      }
      const firstDigit =
        cpfSumFirstCharacter % 11 < 2 ? 0 : 11 - (cpfSumFirstCharacter % 11);
      const secondDigitCalc = cpfSumSecondCharacter + 2 * firstDigit;
      const secondDigit =
        secondDigitCalc % 11 < 2 ? 0 : 11 - (secondDigitCalc % 11);
      const originalDigitsToValidate = this.cpf.substring(
        this.cpf.length - 2,
        this.cpf.length
      );
      const validCpfDigits = `${firstDigit}${secondDigit}`;
      return originalDigitsToValidate === validCpfDigits;
    } catch (e) {
      return false;
    }
  }

  formatCpf() {
    const formatedCpf = this.cpf
      .replaceAll('.', '')
      .replaceAll('-', '')
      .replaceAll(' ', '');

    return formatedCpf;
  }

  isValidLength() {
    return this.cpf.length >= 11 && this.cpf.length <= 14;
  }

  isInvalidFormat() {
    return this.formatCpf()
      .split('')
      .every(digit => digit === this.cpf[0]);
  }
}
