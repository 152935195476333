import shield from '~/assets/icons/shield.svg';
import { useAuth } from '~/hooks';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '~/components';
import { getDomain } from '~/utils';
import {
  Container,
  Content,
  ContentImage,
  ContentParagraph,
  ContentTitle,
  Title,
} from './styles';

const DriverAnalysis = () => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const driverStatus = user.driverAndPassenger;

  console.log(driverStatus);

  useEffect(() => {
    if (!driverStatus) return navigate(`/${getDomain()}`);
    if (driverStatus === 'NullDriver') return navigate(`/${getDomain()}`);
    if (driverStatus === 'ActiveDriver')
      return navigate(`${getDomain()}/cadastro/veiculo/ativo`);
    return setIsLoading(false);
  }, []);

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Title label="Quero dirigir" />
      <Content>
        <ContentImage src={shield} alt="Escudo roxo" />
        <ContentTitle>Seus dados estão em análise.</ContentTitle>
        <ContentParagraph>
          Você será notificado após aprovação via email.
        </ContentParagraph>
      </Content>
    </Container>
  );
};

export default DriverAnalysis;
