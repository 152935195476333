import React from 'react';

import { BsCheckLg, BsWhatsapp } from 'react-icons/bs';
import { ImCancelCircle } from 'react-icons/im';

import { Container, Icon } from './styles';

const NCardItem = ({
  name,
  price,
  onContact,
  onConfirm,
  onCancel,
  ...rest
}) => (
  <Container {...rest}>
    {onContact && (
      <Icon className="iconContact" onClick={onContact}>
        <BsWhatsapp size={20} />
      </Icon>
    )}
    {onCancel && (
      <Icon className="iconCancel" onClick={onCancel}>
        <ImCancelCircle size={20} />
      </Icon>
    )}

    <div className="divLabels">
      {name && <p className="labelName">{name}</p>}

      {name && price && <p className="label">&nbsp;-&nbsp;</p>}

      {price && <p className="labelMoney">{price}</p>}
    </div>
    {onConfirm && (
      <Icon className="iconConfirm" onClick={onConfirm}>
        <BsCheckLg size={16} />
      </Icon>
    )}
  </Container>
);

export default NCardItem;
