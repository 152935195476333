import React, { useState } from 'react';

import { ReactComponent as Icon } from '~/assets/icons/icon_road.svg';

import { IoNavigateCircleOutline } from 'react-icons/io5';

import { Container } from './styles';
import NCardPeopleItem from '../NCardPeopleItem';
import NButton from '../NButton';

const NCardPeople = ({
  name,
  people,
  status,
  onConfirm,
  onMensagem,
  onCancel,
  cost,
  ...rest
}) => {
  const [showName, setShowName] = useState(false);
  return (
    <Container {...rest}>
      <div className="nameContainer">
        <Icon className="icon" />
        <button
          type="button"
          className="button_div"
          onClick={() => setShowName(!showName)}
        >
          {name && (name.length <= 50 || showName) && (
            <p className="labelName">{name}</p>
          )}
          {name && name.length > 50 && !showName && (
            <p className="labelName">{name.slice(0, 50)}...</p>
          )}
        </button>
        <IoNavigateCircleOutline
          size={25}
          className="icon_navigate"
          onClick={() =>
            window
              .open(
                `https://www.google.com/maps/search/?api=1&query=${name.replace(
                  ' ',
                  '+'
                )}`
              )
              .focus()
          }
        />
      </div>
      <div className="peopleDiv">
        <NCardPeopleItem
          name={people?.name}
          onConfirm={onConfirm}
          onCancel={onCancel}
          price={cost}
          status={status}
          className="item"
        />
      </div>
      {onMensagem && (
        <NButton label="Já embarcou" onClick={onMensagem} className="cheguei" />
      )}
    </Container>
  );
};

export default NCardPeople;
