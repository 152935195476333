import { api } from '../api';

export const getVisiblePassenger = ({
  id,
  status = 'canceled',
  skip = 0,
  take = 10,
}) =>
  api.get(
    `/passengers-visible/trip?visibleIdPassenger=${id}&status=${status}&take=${take}&skip=${skip}`
  );
