import React from 'react';
import { TfiLocationPin } from 'react-icons/tfi';

import { RequestCardContainer, RequestCardContent } from './styles';

export function RequestCard({ name, phone, address }) {
  return (
    <RequestCardContainer>
      <RequestCardContent>
        <div>
          <TfiLocationPin />
          {name} - {phone}
        </div>

        <footer>
          <p> {address} </p>
        </footer>
      </RequestCardContent>
    </RequestCardContainer>
  );
}
