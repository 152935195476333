import { api } from '~/services/api';

export const getTripsDriver = () => api.get(`/trips/driver-available/`);
export const getTripsPassenger = routeId =>
  api.get(`/trips/passenger-available/?route=${routeId}`);

export const getTrips = () => api.get(`/trips/`);

export const getTripById = id => api.get(`/trips/${id}`);

export const updateWaitingTrip = (id, data) =>
  api.put(`/trips-passenger/${id}`, data);

export const alertCustomer = (id, data) =>
  api.post('/alert-customer', { tripId: id, ...data });

export const getTimes = () => api.get(`/trips/available-times`);

export async function incrementSeats(changeSeats, id) {
  try {
    const response = await api.post(`/trips/${id}/change-seats`, {
      seats: changeSeats,
    });
    return response.data;
  } catch (error) {
    return error.status;
  }
}

export async function getSharedTrip(
  boardingLatitude,
  boardingLongitude,
  boardingRadius,
  landingLatitude,
  landingLongitude,
  landingRadius,
  seats,
  categories
) {
  try {
    const response = await api.get(
      `/trips/available-for-share/?boardingLatitude=${boardingLatitude}&boardingLongitude=${boardingLongitude}&boardingRadius=${boardingRadius}&landingLatitude=${landingLatitude}&landingLongitude=${landingLongitude}&landingRadius=${landingRadius}&seats=${seats}&categories=${categories}`
    );
    return response.data;
  } catch (error) {
    return error.status;
  }
}

export async function createTrip(data) {
  try {
    const response = await api.post(`/trips/create-trip`, data);
    return response.data;
  } catch (error) {
    return error.status;
  }
}
