import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 8px;

  ${({ theme }) => theme.styles.cardWidth}
  border-radius: 20px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  z-index: 2 !important;

  display: flex;
  flex-direction: column;

  justify-content: center;

  .pencil-icon {
    margin-left: 8px;
    cursor: pointer;
  }

  .justify-between {
    justify-content: space-between;
  }

  .flex-col {
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
      font-size: 13px;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;

    > img {
      margin-left: 8px;
      object-fit: cover;
    }
  }

  > header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    position: relative;

    > .rate-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      right: 0;
      border: none;
    }

    > div {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    .date-price {
      font-size: 12px;
    }

    > img {
      width: 50px;
      height: 50px;
      margin-left: 16px;
      border-radius: 50%;
      border: 1px solid #4d4d4d;
      margin-right: 16px;
    }
  }

  > .places {
    margin-bottom: 12px;
  }

  .rightContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }

  .button_div {
    filter: none;
    padding-right: 16px;

    .mt {
      margin-top: 16px;
    }

    > .origin-street {
      margin-bottom: 4px;
    }

    > .origin-street,
    > .destiny-street {
      font-size: 13px;
      width: 100%;
    }

    display: flex;
    flex-direction: column;
    text-align: start;
    align-items: start;
  }

  > .places,
  > .origin-street,
  > .destiny-street {
    margin-left: 16px;
    font-size: 16px;
  }

  .icon {
    filter: none;
    margin-right: 12px;
  }

  > .places {
    color: black;
  }

  > .item-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .divisorWrapper {
    margin-bottom: 1rem;
    display: flex;
    width: 100%;
    justify-content: center;
    filter: brightness(50%) saturate(50%);
  }

  .sourceWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1rem;
    .sourceButtons {
      width: 90%;
      display: flex;
      justify-content: space-between;
      gap: 10px;

      .copy {
        width: 40%;
        padding: 10px;
        border-radius: 25px;
        border: 1px solid black;
        color: #4d4d4d;
      }

      .invert {
        width: 60%;
        padding: 10px;
        border-radius: 25px;
        border: 1px solid black;
        color: #4d4d4d;
      }

      #selected {
        background-color: #c8c8c8;
        color: #fff !important;
      }
    }
  }
`;
