import { css } from 'styled-components';

import { getAppColors } from '~/repositories/app';

const colors = getAppColors();

const theme = {
  colors: {
    primary: colors?.primary || '#944BBB',
    dark: '#4D4D4D',
    gray: '#9A9A9A',
    light_gray: '#E5E5E5',
    red: '#FF3131',
    white: '#ffffff',
    purple: '#944BBB',
  },
  styles: {
    cardWidth: css`
      width: calc(100vw - 48px);
      max-width: 544px;
    `,
    itemWidth: css`
      width: calc(100vw - 96px);
      max-width: 500px;
    `,
  },
};

export default theme;
