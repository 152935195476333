import styled from 'styled-components';

import { Link } from 'react-router-dom';

export const RequestContainer = styled.main`
  height: 100vh;
  background: #d2d2d2;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  background: ${({ theme }) => theme.colors.primary};

  padding: 5rem 2rem 6rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  h2 {
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.white};
  }

  span {
    background: ${({ theme }) => theme.colors.white};

    width: 2rem;
    height: 2rem;
    border-radius: 99999px;

    font-weight: 600;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BackButton = styled(Link)`
  width: 2rem;
  height: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.5rem;
  }
`;

export const PriceTitle = styled.strong`
  margin-top: 1.75rem;

  width: 100%;
  text-align: center;

  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const CardsContainer = styled.section`
  margin-top: -4rem;
  padding: 0 2rem 1rem;

  overflow: auto;

  flex: 1;
`;

export const Footer = styled.footer`
  width: 100%;
  padding: 1rem 2rem;
`;
