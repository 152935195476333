import { getAccountId } from '~/repositories/auth';
import { api, apiForm } from '~/services/api';

export const createDriver = data => apiForm.post('/drivers', data);

export const getDriver = () => api.get(`/drivers/${getAccountId()}`);
export const getDriverById = id => api.get(`/drivers/${id}`);

export const updateDriver = data =>
  apiForm.put(`/drivers/${getAccountId()}`, data);

export const updateDriverLogin = data =>
  api.put(`/drivers/${getAccountId()}`, data);

export const deleteDriver = () => api.delete(`/drivers/${getAccountId()}`);

export const verifyDriverRegister = data =>
  api.post('/drivers/verify-register', data);

export const verifyDriverCar = data => api.post('/drivers/verify-car', data);

export const getTripsHistory = page =>
  api.get(`/drivers/${getAccountId()}/trips?page=${page}`);

export const getDriverGains = () =>
  api.get(`/drivers/${getAccountId()}/financial`);
