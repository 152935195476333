/* eslint-disable no-nested-ternary */
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { NButton, NInput, NPicture } from '~/components';
import { useAlerts, useApi } from '~/hooks';
import { verifyDriverRegister } from '~/services/drivers';
import { verifyPassengerRegister } from '~/services/passengers';

import { getRegister, setRegister } from '~/repositories/register';
import { getDomain } from '~/utils';
import { Cpf } from '~/utils/cpfValidator';
import useErrors from './errors';
import { driverSchema, extraFields, fields, passengerSchema } from './props';
import { Container } from './styles';

const Form = () => {
  const navigate = useNavigate();
  const save = getRegister();

  const [type] = useState({
    label: 'Passageiro',
    value: 'passenger',
  });
  const [isInvalidCpf, setIsInvalidCpf] = useState(false);
  const showCpfErrorMessage = label =>
    isInvalidCpf && label === 'CPF:' ? 'Cpf inválido' : false;

  const { register, control, formState, handleSubmit, setError } = useForm({
    resolver: yupResolver(
      type.value === 'passenger' ? passengerSchema : driverSchema
    ),
    mode: 'all',
  });

  const { newErrorAlert } = useAlerts();

  const errorHandler = useErrors(setError);

  const { request } = useApi();

  const onSubmit = data => {
    if (type.value === 'passenger') {
      delete data.pix;

      setRegister({
        ...(save || {}),
        data: {
          ...data,
          passwordMatch: '',
          password: '',
        },
      });
    }

    const { passwordMatch, picture, ...reqData } = data;

    reqData.cpf = reqData.cpf.replace(/\D/g, '');

    const verifyRegister =
      type.value === 'passenger'
        ? verifyPassengerRegister
        : verifyDriverRegister;

    if (new Cpf(reqData.cpf).cpfValidator() !== true) {
      setIsInvalidCpf(true);
      return;
    }

    if (type.value === 'driver' && !data.picture && !save?.data?.picture) {
      newErrorAlert('Por favor, coloque uma foto');
      return;
    }

    if (!save?.data?.picture && type.value !== 'passenger') {
      const fileRead = new FileReader();

      fileRead.readAsDataURL(data.picture);
      fileRead.addEventListener('load', () => {
        setRegister({
          ...(save || {}),
          data: {
            ...data,
            passwordMatch: '',
            password: '',
            picture: fileRead.result,
          },
        });
      });
    }

    request(
      () => verifyRegister(reqData),
      () => {
        if (type.value === 'passenger') {
          navigate(`/${getDomain()}/cadastro/telefone`, {
            state: { type: type.value, reqData, phoneConfirmation: true },
          });
        } else {
          navigate(`/${getDomain()}/cadastro/veiculo`, {
            state: { ...data },
          });
        }
      },
      errorHandler
    );
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {/* <NSelector
        label="Tipo da conta"
        options={[
          'Cliente',
          'Passageiro',
          'Motorista',
          'Entregador',
          'Estabelecimento',
        ]}
        value={type.label}
        onChange={selectedType => {
          switch (selectedType) {
            case 'Cliente':
              setType({ label: 'Cliente', value: 'passenger' });
              break;
            case 'Passageiro':
              setType({ label: 'Passageiro', value: 'passenger' });
              break;
            case 'Motorista':
              setType({ label: 'Motorista', value: 'driver' });
              break;
            case 'Entregador':
              setType({ label: 'Entregador', value: 'driver' });
              break;
            case 'Estabelecimento':
              setType({ label: 'Estabelecimento', value: 'passenger' });
              break;
            default:
              setType('');
          }
        }}
      /> */}
      {type.value === 'driver' && (
        <div className="div_pic">
          <p>Insira sua foto aqui:</p>
          <Controller
            control={control}
            name="picture"
            defaultValue={save?.data?.picture}
            render={({ field }) => (
              <NPicture id="foto" {...field} imgDefault={save?.data?.picture} />
            )}
            rules={{ required: true }}
          />
        </div>
      )}

      {!!type.value &&
        Object.entries(fields).map(([key, value]) => (
          <NInput
            key={key}
            error={
              formState.errors[key]?.message || showCpfErrorMessage(value.label)
            }
            defaultValue={save?.data?.[key]}
            {...value}
            {...register(key)}
          />
        ))}

      {type.value === 'driver' &&
        Object.entries(extraFields).map(([key, value]) => (
          <NInput
            key={key}
            error={formState.errors[key]?.message}
            defaultValue={save?.data?.[key]}
            {...value}
            {...register(key)}
          />
        ))}

      <NButton
        className="submit-button"
        type="submit"
        label="Prosseguir"
        disabled={!type.value}
      />
    </Container>
  );
};

export default Form;
