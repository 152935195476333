import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Background,
  Loading,
  NButton,
  NCardHistory,
  NModalSure,
  Sidebar,
} from '~/components';
import { useAlerts, useApi, useApiEffect, useAuth } from '~/hooks';
import { getTripsHistory, updatePassenger } from '~/services/passengers';
import { getCorrectDate } from '~/utils/correctTime';

import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BsCheckCircle, BsChevronRight } from 'react-icons/bs';
import { ReactComponent as FrontCar } from '~/assets/icons/front-car.svg';
import { ReactComponent as Location } from '~/assets/icons/location.svg';
import { ReactComponent as PassengerPlus } from '~/assets/icons/passenger-plus.svg';
import { ReactComponent as Shop } from '~/assets/icons/shop.svg';
import NCard from '~/components/NCard';
import { HomeCardContainer } from '~/components/NCard/styles';
import { getAuth } from '~/repositories/auth';
import { getDomain } from '~/utils';
import storage from '~/repositories/storage';
import canceledTripImg from '../../../assets/icons/canceled_trip.png';

import { Container, UserStatus } from './styles';

const Trips = () => {
  const [trips, setTrips] = useState([]);
  const [pages, setPages] = useState([]);
  const [showImage, setImage] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const disembarkationTripId = storage.getItem('desembarque');
  const tripUrlSplitted = window.location.href.split('/viagens');

  const { userId } = getAuth();
  const { user, setUser } = useAuth();

  const hasEmail = user.email !== null;

  const navigate = useNavigate();

  const { request } = useApi();

  const { newErrorAlert } = useAlerts();

  useEffect(() => {
    if (
      disembarkationTripId &&
      disembarkationTripId !== '' &&
      disembarkationTripId !== 'undefined'
    ) {
      storage.setItem('desembarque', undefined);
      navigate(`/${getDomain()}/desembarque/${disembarkationTripId}`);
    } else if (tripUrlSplitted[1]) {
      navigate(`/${getDomain()}/`, {
        state: {
          tripUrl: `/${getDomain()}/viagens${tripUrlSplitted[1]}`,
        },
      });
    }
  }, []);

  const attData = data => {
    if (data.content.length < 1) setImage(true);
    setTrips(data.content);
    setPages(data.pages);
  };

  const changePage = pageNumber => {
    setPage(pageNumber);
    request(
      () => getTripsHistory(pageNumber),
      response => attData(response.data)
    );
  };

  useApiEffect(
    () => getTripsHistory(page),
    response => {
      attData(response.data);
      setIsLoading(false);
    }
  );

  const date = finishedAt => {
    if (finishedAt) {
      const { newData } = getCorrectDate(finishedAt);
      return newData;
    }
    return 'Cancelada';
  };

  const itDriver = driver => {
    if (driver) return driver.name;
    return 'Cancelado';
  };

  const itDriverPhoto = driver => {
    if (driver) return driver.photo;
    return canceledTripImg;
  };

  const itPix = driver => {
    if (driver) return driver.pix;
    return undefined;
  };

  const tripAgain = trip => {
    const landingDistrict = {
      name: trip.route.landingDistrict,
    };
    if (!trip.route.available) {
      newErrorAlert('Essa rota não esta mais disponível :(');
    } else {
      navigate(`/${getDomain()}/horarios`, {
        state: {
          addressEmbarkation: trip.addresses.boarding,
          addressLanding: trip.addresses.landing,
          disembarkation: landingDistrict,
          reversed: trip.reversed,
          routeId: trip.route.id,
          hasReturn: trip.hasReturn,
        },
      });
    }
  };

  const tripChangeBoarding = trip => {
    const landingDistrict = {
      name: trip.route.landingDistrict,
      id: '7a20a3b8-55db-4d6b-b607-7a198e31de7f',
    };
    const boardingDistrict = {
      name: trip.route.landingDistrict,
      id: '91fdd84a-1644-42a1-941c-1b437f93d19d',
    };

    if (!trip.route.available) {
      newErrorAlert('Essa rota não esta mais disponível :(');
    } else {
      navigate(`/${getDomain()}/embarque`, {
        state: {
          addressLanding: trip.addresses.landing,
          embarkation: boardingDistrict,
          disembarkation: landingDistrict,
          reversed: trip.reversed,
          routeId: trip.route.id,
          hasReturn: trip.hasReturn,
        },
      });
    }
  };

  const tripChangeLanding = trip => {
    const landingDistrict = {
      name: trip.route.landingDistrict,
      id: '7a20a3b8-55db-4d6b-b607-7a198e31de7f',
    };
    if (!trip.route.available) {
      newErrorAlert('Essa rota não esta mais disponível :(');
    } else {
      navigate(`/${getDomain()}/desembarque`, {
        state: {
          addressEmbarkation: trip.addresses.boarding,
          disembarkation: landingDistrict,
          reversed: trip.reversed,
          routeId: trip.route.id,
          hasReturn: trip.hasReturn,
        },
      });
    }
  };

  const cardsTrips = trips.map((trip, i) => (
    <NCardHistory
      key={i}
      id={trip.id}
      type={trip.type}
      date={date(trip.finishedAt)}
      rate={trip.rating}
      time={trip.time.slice(0, 5)}
      price={trip.cost.total}
      originNeighbour={trip.route.boardingDistrict}
      destinyNeighbour={trip.route.landingDistrict}
      origin={trip.addresses.boarding.name}
      destiny={trip.addresses.landing.name}
      driver={itDriver(trip.driver)}
      driverPhoto={itDriverPhoto(trip.driver)}
      goToTrip={() => tripAgain(trip)}
      changeBoarding={() => tripChangeBoarding(trip)}
      changeLanding={() => tripChangeLanding(trip)}
      pix={itPix(trip.driver)}
      className="card"
    />
  ));

  const verifyEmail = (url, linkState) => {
    if (hasEmail) {
      return navigate(url, { state: { userId: linkState } });
    }
    return setIsModalOpen(true);
  };

  const setAsEstablishments = () => {
    if (!user.establishments) {
      return request(
        () => updatePassenger({ establishments: true }),
        response => {
          setUser(response.data.passenger);
          navigate(`/${getDomain()}/establishment`);
        }
      );
    }
    return navigate(`/${getDomain()}/establishment`);
  };

  const homeCards = [
    {
      title: 'Passageiro',
      buttonLabel: 'Iniciar uma viagem',
      url: `${getDomain()}/home`,
      icon: <PassengerPlus />,
    },
    {
      title: 'Estabelecimento',
      buttonLabel: 'Criar links',
      url: `${getDomain()}/establishment`,
      action: () => setAsEstablishments(),
      icon: <Shop />,
    },
    {
      title: 'Motorista',
      buttonLabel: 'Cadastrar veículo',
      url: `${getDomain()}/cadastro/veiculo`,
      action: () => verifyEmail(`${getDomain()}/cadastro/veiculo`, userId),
      icon: <FrontCar />,
      state: userId,
    },
    {
      title: 'Entregador',
      buttonLabel: 'Cadastrar veículo',
      url: `${getDomain()}/cadastro/veiculo`,
      icon: <Location />,
      action: () => verifyEmail(`${getDomain()}/cadastro/veiculo`, userId),
      state: userId,
    },
  ];

  const homeCardsMarkup = (
    <HomeCardContainer>
      {homeCards.map(item => (
        <NCard
          key={item.url}
          icon={item.icon}
          title={item.title}
          buttonLabel={item.buttonLabel}
          url={item.url}
          linkState={item.state}
          action={item.action}
        />
      ))}
    </HomeCardContainer>
  );

  const driverStatus = user.driverAndPassenger;

  // eslint-disable-next-line react/no-unstable-nested-components
  const DriverStatusIcon = () =>
    driverStatus === 'ActiveDriver' ? (
      <BsCheckCircle size={30} color="#4D4D4D" />
    ) : (
      <AiOutlineInfoCircle size={30} color="#fff" />
    );

  const driverStatusLabel =
    driverStatus === 'ActiveDriver'
      ? 'Sua conta Motorista foi aprovada!'
      : 'Seus dados estão em análise';

  const driverStatusUrl =
    driverStatus === 'ActiveDriver'
      ? `${getDomain()}/cadastro/veiculo/ativo`
      : `${getDomain()}/cadastro/veiculo/analise`;

  console.log(user);

  console.log(driverStatusUrl);

  console.log(driverStatus);

  return (
    <Container>
      <Background />
      <div className="div_top">
        <Sidebar />
        {showImage && <h1>Escolha um perfil</h1>}
        {!showImage && !isLoading ? (
          <NButton
            label="Nova chamada"
            className="button"
            color="white"
            onClick={() => navigate(`/${getDomain()}/home`)}
          />
        ) : null}
      </div>

      {driverStatus ? (
        <UserStatus
          status={driverStatus}
          to={driverStatusUrl}
          state={{ userId }}
        >
          <DriverStatusIcon />
          <div>
            <span>{driverStatusLabel}</span>
          </div>
          <BsChevronRight
            size={30}
            color={driverStatus === 'ActiveDriver' ? '#4D4D4D' : '#fff'}
          />
        </UserStatus>
      ) : null}

      {!showImage && cardsTrips}

      {showImage && homeCardsMarkup}

      {pages && pages.total > 1 && (
        <div className="div_next">
          {pages.current > 1 && (
            <button
              onClick={() => changePage(page - 1)}
              type="button"
              className="advanced"
            >
              {`<`}
            </button>
          )}
          {pages.current < pages.total && (
            <button
              onClick={() => changePage(page + 1)}
              type="button"
              className="advanced"
            >
              &gt;
            </button>
          )}
        </div>
      )}
      {isLoading && <Loading />}
      <NModalSure
        visible={isModalOpen}
        label="Seu e-mail ainda não foi confirmado"
        cancelLabel="Cancelar"
        confirmLabel="Confirmar e-mail"
        onCancel={() => setIsModalOpen(false)}
        onConfirm={() => navigate(`${getDomain()}/perfil`)}
        onClose={() => setIsModalOpen(false)}
      />
    </Container>
  );
};

export default Trips;
