import { getApp, setAppDomain } from '~/repositories/app';
import getDomain from './getDomain';

setAppDomain(getDomain());

const { name, colors, domain } = getApp();

// if (!domain) {
//   throw new Error('O valor de "domain" não está definido.');
// }

const startUrl = `${window.location.origin}/${domain}`;

export const manifest = {
  name: `${name || 'Weptek'} Travel App`,
  short_name: name || 'Weptek',
  lang: 'pt-bt',
  start_url: startUrl,
  display: 'standalone',
  background_color: colors?.primary || '#944BBB',
  theme_color: colors?.primary || '#944BBB',
  description: 'O melhor app de transporte e entregas',
  icons: [
    {
      src: `${window.location.origin}/maskable_icon_x48.png`,
      sizes: '48x48',
      type: 'image/png',
      purpose: 'maskable',
    },
    {
      src: `${window.location.origin}/maskable_icon_x72.png`,
      sizes: '72x72',
      type: 'image/png',
      purpose: 'maskable',
    },
    {
      src: `${window.location.origin}/maskable_icon_x96.png`,
      sizes: '96x96',
      type: 'image/png',
      purpose: 'maskable',
    },
    {
      src: `${window.location.origin}/maskable_icon_x128.png`,
      sizes: '640x320',
      type: 'image/png',
      purpose: 'maskable',
    },
    {
      src: `${window.location.origin}/maskable_icon_x192.png`,
      sizes: '192x192',
      type: 'image/png',
    },
  ],
  screenshots: [
    {
      src: `${window.location.origin}/1.png`,
      sizes: '640x320',
      type: 'image/png',
      purpose: 'maskable',
    },
    {
      src: `${window.location.origin}/2.png`,
      sizes: '640x320',
      type: 'image/png',
      purpose: 'maskable',
    },
    {
      src: `${window.location.origin}/3.png`,
      sizes: '640x320',
      type: 'image/png',
    },
  ],

  prefer_related_applications: false,
};

export const updateManifest = () => {
  const manifestElement = document.getElementById('manifest-link');
  const manifestString = JSON.stringify(manifest);
  manifestElement?.setAttribute(
    'href',
    `data:application/json;charset=utf-8,${encodeURIComponent(manifestString)}`
  );
};
