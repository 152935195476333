import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon {
    color: ${({ theme }) => theme.colors.primary};
  }

  .title {
    margin: 20px 0 10px;

    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
  }

  .label {
    max-width: min(90%, 380px);

    text-align: center;
  }
`;
