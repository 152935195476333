import React, { createContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { Global, theme } from '~/styles';

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const [app, setApp] = useState({
    colors: theme.colors,
    logo: null,
  });

  const updateApp = data => {
    const newApp = {
      ...data,
      colors: {
        ...theme.colors,
        primary: data.colors?.primary || theme.colors.primary,
      },
      logo: data.logo || app.logo,
    };
    setApp(newApp);
  };

  return (
    <AppContext.Provider value={{ app, setApp, updateApp }}>
      <ThemeProvider theme={{ ...theme, colors: app.colors }}>
        <Global />
        {children}
      </ThemeProvider>
    </AppContext.Provider>
  );
};
