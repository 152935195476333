import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { FaEdit } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { NTitle, NButton, NInput, NPicture, NModalSure } from '~/components';
import { useApi, useAuth } from '~/hooks';
import { removeAuth } from '~/repositories/auth';
import { deleteDriver, updateDriver } from '~/services/drivers';
import {
  confirmEmailKey,
  confirmPhoneKey,
  validateEmail,
  validatePhone,
} from '~/services/users';
import { getDomain } from '~/utils';

import Confirm from '../../Auth/Register/Confirm';

import { Container } from './styles';

const Profile = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, control } = useForm();

  const { user, setUser } = useAuth();
  const { request } = useApi();

  const [modal, setModal] = useState(false);

  const onSubmit = data => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });

    request(
      () => updateDriver(formData),
      response => {
        setUser(response.data);
        return 'Informações alteradas com sucesso!';
      }
    );
  };

  const onExclude = () => {
    request(
      () => deleteDriver(),
      () => {
        removeAuth();
        window.location.reload();
        return 'Conta deletada com sucesso';
      }
    );
  };

  return (
    <Container>
      <NTitle
        label="Perfil de Motorista"
        className="title"
        onBack={() => navigate(`/${getDomain()}/`)}
      />
      <FaEdit size={30} className="icon_edit" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="photoImage"
          render={({ field }) => (
            <NPicture id="photoImage" imgDefault={user.photo} {...field} />
          )}
        />

        <NInput label="Nome" placeholder={user.name} {...register('name')} />

        <Link to={`${getDomain()}/perfil/email`}>
          <NInput label="Email" placeholder={user.email} disabled />
        </Link>

        <Link to={`${getDomain()}/perfil/phone`}>
          <NInput label="Telefone" placeholder={user.phone} disabled />
        </Link>

        <NInput label="Pix" placeholder={user.pix} {...register('pix')} />

        <div className="div_info">
          <p>Placa do Veículo: {user.car.licensePlate.value}</p>
          <p>CPF: {user.cpf}</p>
          <p>
            Marca modelo e cor de veiculo: {user.car.brand} {user.car.model}{' '}
            {user.car.color}
          </p>
        </div>

        <NButton type="submit" label="Salvar alterações" />
      </form>

      <NButton
        label="Excluir conta"
        className="btn_exclude"
        color="red"
        onClick={() => setModal(true)}
      />

      <NModalSure
        visible={modal}
        label="Tem certeza que deseja excluir sua conta?"
        onCancel={() => setModal(false)}
        onConfirm={() => onExclude()}
        confirmLabel="Excluir"
        cancelLabel="Cancelar"
        onClose={() => setModal(false)}
      />
    </Container>
  );
};

Profile.Phone = () => {
  const update = phoneKey => {
    const formData = new FormData();

    formData.append('phoneKey', phoneKey);

    return updateDriver(formData);
  };

  return (
    <Confirm
      title="Verificar número"
      type="SMS"
      name="phone"
      sendService={validatePhone}
      confirmService={confirmPhoneKey}
      updateService={update}
    />
  );
};

Profile.Email = () => {
  const update = emailKey => {
    const formData = new FormData();

    formData.append('emailKey', emailKey);

    return updateDriver(formData);
  };

  return (
    <Confirm
      title="Verificar email"
      type="Email"
      name="email"
      sendService={validateEmail}
      confirmService={confirmEmailKey}
      updateService={update}
    />
  );
};

export default Profile;
