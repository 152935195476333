import { ActionButton, Button, Container } from './styles';

const NCard = ({
  title,
  icon,
  url,
  action,
  buttonLabel,
  linkState,
  ...rest
}) => (
  <Container {...rest}>
    <div className="header">
      <h2 className="title">{title}</h2>
      {icon}
    </div>
    {action ? (
      <ActionButton onClick={() => action()}>{buttonLabel}</ActionButton>
    ) : (
      <Button to={url} state={{ userId: linkState }}>
        {buttonLabel}
      </Button>
    )}
  </Container>
);

export default NCard;
