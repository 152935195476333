const formErrors = setError => response => {
  if (response.status === 400) {
    if (response.data.details?.cpf) {
      if (response.data.details.cpf.match(/unique/)) {
        setError('cpf', { message: 'CPF já utilizado!' });

        return false;
      }

      setError('cpf', { message: 'CPF inválido!' });

      return false;
    }

    if (response.data.details?.pix) {
      setError('pix', { message: 'Chave PIX inválida!' });

      return false;
    }
  }

  return true;
};

export default formErrors;
