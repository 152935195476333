import useAlerts from './useAlerts';

const solveResponse = (data, response) => {
  if (typeof data === 'string' || typeof data === 'boolean') {
    return data;
  }

  if (typeof data === 'object') {
    if (response) {
      return solveResponse(data[response.status]);
    }

    return true;
  }

  if (typeof data === 'function') {
    return solveResponse(data(response));
  }

  return null;
};

const useApi = () => {
  const { newErrorAlert, newSuccessAlert } = useAlerts();

  const request = async (
    service = () => null,
    callback = () => null,
    ...errorHandlers
  ) => {
    try {
      const response = await service();

      const handled = solveResponse(callback, response);

      if (handled) {
        newSuccessAlert(handled);
      }
    } catch (error) {
      for (let i = 0; i < errorHandlers.length; i += 1) {
        const handled = solveResponse(errorHandlers[i], error.response);

        if (handled !== true) {
          if (handled) {
            newErrorAlert(handled);
          }

          break;
        }
      }
    }
  };

  return { request };
};

export default useApi;
