import React from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';
import * as Dialog from '@radix-ui/react-dialog';

import { Button } from './components/Button';
import { RequestCard } from './components/RequestCard';
import { AddRequestModal } from './components/AddRequestModal';

import {
  BackButton,
  CardsContainer,
  Footer,
  Header,
  PriceTitle,
  RequestContainer,
} from './styles';

export default function Requests() {
  return (
    <RequestContainer>
      <Header>
        <BackButton to="/">
          <HiOutlineArrowLeft />
        </BackButton>

        <h2> Pedidos </h2>

        <span> 2 </span>

        <PriceTitle>RS10,00</PriceTitle>
      </Header>

      <CardsContainer>
        <RequestCard
          name="Gabriel Luciano"
          phone="31993513343"
          address="Rua Prado, 99, Centro de nova lima"
        />

        <RequestCard
          name="Gabriel Luciano"
          phone="31993513343"
          address="Rua Prado, 99, Centro de nova lima"
        />

        <RequestCard
          name="Gabriel Luciano"
          phone="31993513343"
          address="Rua Prado, 99, Centro de nova lima"
        />

        <RequestCard
          name="Gabriel Luciano"
          phone="31993513343"
          address="Rua Prado, 99, Centro de nova lima"
        />

        <RequestCard
          name="Gabriel Luciano"
          phone="31993513343"
          address="Rua Prado, 99, Centro de nova lima"
        />

        <RequestCard
          name="Gabriel Luciano"
          phone="31993513343"
          address="Rua Prado, 99, Centro de nova lima"
        />
      </CardsContainer>

      <Footer>
        <Dialog.Root open>
          <Dialog.Trigger asChild>
            <Button text="Adicionar pedido" variant="secondary" />
          </Dialog.Trigger>

          <AddRequestModal />
        </Dialog.Root>

        <Button text="Finalizar" variant="primary" />
      </Footer>
    </RequestContainer>
  );
}
