import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 1;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  align-content: center;

  .button {
    margin-top: 20px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 265px;
  align-items: center;
`;

export const Header = styled.div`
  margin-top: 40px;
`;

export const BackButton = styled.div`
  position: absolute;
  top: 64px;
  left: 0;
  padding-left: 30px;
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  img {
    width: 50px;
    height: 50px;
  }
`;

export const TitleConfirm = styled.h1`
  font-family: 'Comfortaa';
  font-size: 30px;
  color: #000000;
  text-align: center;
  width: 273px;
  font-weight: 400;
`;

export const ButtonConfirm = styled.button`
  color: #ffffff;
  padding: 16px 21px;
  background-color: ${props => props.buttonConfirmColor};
  width: 234px;
  border-radius: 10px;
  font-family: 'Comfortaa';
  font-size: 16px;
`;
