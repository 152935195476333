import React from 'react';
import { TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-routing-machine';
import iconCar from '~/assets/icons/car-map.png';
import iconPoint from '~/assets/icons/point-map.png';
import Routing from './utils/MapRouting';
import { Container } from './style';

const Map = ({ driverLocation, passengerAddress }) => {
  const carIcon = L.icon({
    className: 'icon',
    iconUrl: iconCar,
    iconSize: [30, 30],
  });
  const pointIcon = L.icon({
    className: 'icon',
    iconUrl: iconPoint,
    iconSize: [30, 30],
  });

  return (
    <Container zoom={13} className="map">
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Routing
        driverLocation={driverLocation}
        passengerAddress={passengerAddress}
      />
      {driverLocation ? (
        <>
          <Marker
            position={[driverLocation.latitude, driverLocation.longitude]}
            icon={carIcon}
          />
          <Marker
            position={[
              passengerAddress.boardingCoordinates.lat,
              passengerAddress.boardingCoordinates.lng,
            ]}
            icon={pointIcon}
          />
        </>
      ) : (
        <>
          <Marker
            position={[
              passengerAddress.landingCoordinates.lat,
              passengerAddress.landingCoordinates.lng,
            ]}
            icon={pointIcon}
          />
          <Marker
            position={[
              passengerAddress.boardingCoordinates.lat,
              passengerAddress.boardingCoordinates.lng,
            ]}
            icon={pointIcon}
          />
        </>
      )}
    </Container>
  );
};

export default Map;
