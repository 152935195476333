import { useEffect, useState } from 'react';

import { useApp } from '~/hooks';

import loadingIcon from '~/assets/icons/loading-buffering.gif';
import { MdOutlineContentCopy } from 'react-icons/md';

import { getStatus } from '~/repositories/status';
import { copyText } from '~/utils/copyText';
import NModalWaiting from '../NModalWaiting';
import { ButtonExitFooter } from '../ButtonExitFooter';

import {
  ButtonMessage,
  ButtonSpeed,
  ContainerButton,
  ContainerPoints,
  ContainerRunning,
  CopiedPix,
  DateRide,
  DriverInfos,
  DriverInfosCar,
  DriverName,
  DriverPrice,
  FooterContainer,
  FooterWaiting,
  LoadingIcon,
  PixDriver,
  PixName,
  PriceContainer,
  Progress,
  TimeOverlay,
  TitleWaitingPassagers,
  ValueTrip,
  Waiting,
  WaitingDriver,
  WaitingPassagers,
} from './style';

export function NFooterWaiting({
  driver,
  actualTrip,
  waiting,
  cost,
  handleButtonClickModal,
  handleIncreaseSeats,
  message,
  timeUser,
  driveTime,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  // const maxProgress = 100;
  // const timeLimit = 600000;
  const initialStartTime =
    localStorage.getItem('startTime') || new Date().getTime();
  const initialProgress = parseFloat(localStorage.getItem('progress')) || 0;

  // const [progress, setProgress] = useState(initialProgress);
  const [progress] = useState(initialProgress);
  const [startTime] = useState(initialStartTime);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const currentTime = new Date().getTime();
  //     const elapsedTime = currentTime - startTime;

  //     if (elapsedTime < timeLimit) {
  //       const increment = (elapsedTime / timeLimit) * maxProgress;
  //       setProgress(increment);
  //       localStorage.setItem('progress', increment.toString());
  //     } else {
  //       clearInterval(interval);
  //       localStorage.clear();
  //       setProgress(0);
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [startTime]);

  useEffect(() => {
    localStorage.setItem('startTime', startTime.toString());
  }, [startTime]);

  const status = getStatus();

  const { app } = useApp();

  return (
    <FooterWaiting>
      <FooterContainer>
        <PriceContainer>
          {actualTrip.status === 'waiting_passengers' && (
            <WaitingPassagers>
              <Progress
                progressColor={app.colors.primary}
                value={progress}
                max="100"
              />
              <ValueTrip>{cost}</ValueTrip>
              <TitleWaitingPassagers>
                Compartilhando esse chamado
              </TitleWaitingPassagers>
              <ButtonSpeed
                buttonColorSpeed={app.colors.primary}
                onClick={() => setModalOpen(true)}
              >
                Acelerar
              </ButtonSpeed>
              <NModalWaiting
                handleIncreaseSeats={handleIncreaseSeats}
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
              />
              <ButtonExitFooter onClick={handleButtonClickModal} />
            </WaitingPassagers>
          )}

          {actualTrip.status === 'waiting_driver' && (
            <>
              <Waiting>{waiting}</Waiting>
              <LoadingIcon
                className="loadingIconStyle"
                src={loadingIcon}
                alt="carregando"
              />
              <ButtonExitFooter onClick={handleButtonClickModal} />
            </>
          )}
        </PriceContainer>

        {driver && status === 'waiting' && (
          <WaitingDriver>
            <TimeOverlay>
              {driveTime.time} <span>Chegada: {driveTime.hour}</span>
            </TimeOverlay>
            <DriverInfosCar>
              {driver.car.licensePlate.value}
              <span>
                {driver.car.model} - {driver?.car.color}
              </span>
            </DriverInfosCar>
            <DriverInfos>
              <img src={driver?.photo} alt="logo img" />
              <div>
                <DriverName>{driver.name}</DriverName>
                <DateRide>10/10/2023 - 100 viagens</DateRide>
              </div>
              <ContainerPoints>
                <p>100</p>
                <span>Pontos</span>
              </ContainerPoints>
            </DriverInfos>
            <ContainerButton>
              <ButtonMessage onClick={message}>Mensagem</ButtonMessage>
              <ButtonExitFooter onClick={handleButtonClickModal} />
            </ContainerButton>
          </WaitingDriver>
        )}

        {status === 'running' && (
          <ContainerRunning>
            <TimeOverlay>
              {timeUser.time} <span>Chegada:{timeUser.hour}</span>
            </TimeOverlay>
            <DriverPrice>
              {driver?.name} - {cost}
            </DriverPrice>
            <PixDriver>
              <PixName pixColor={app.colors.primary}>Pix:</PixName>
              <CopiedPix>{driver?.pix}</CopiedPix>
              <MdOutlineContentCopy
                onClick={() => copyText(driver?.pix)}
                size={25}
                color={app.colors.primary}
              />
            </PixDriver>
          </ContainerRunning>
        )}
      </FooterContainer>
    </FooterWaiting>
  );
}
