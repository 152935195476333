import React from 'react';

import { Container } from './styles';

const NButtonLink = ({ label = '', textColor = '', ...rest }) => (
  <Container textColor={textColor} {...rest}>
    {label}
  </Container>
);

export default NButtonLink;
