import { object, string, number } from 'yup';

export const fields = {
  carColor: {
    type: 'text',
    label: 'Cor do seu veículo:',
    placeholder: 'Insira a cor do carro',
  },
  carYear: {
    label: 'Ano do seu veiculo:',
    placeholder: 'Insira o ano do seu carro',
  },
  carModel: {
    type: 'text',
    label: 'Modelo do veículo:',
    placeholder: 'Insira o modelo do seu veículo',
  },
  licensePlate: {
    type: 'text',
    mask: 'aaa-****',
    label: 'Placa do veículo:',
    placeholder: 'Insira a placa do seu veículo',
  },
  carBrand: {
    type: 'text',
    label: 'Marca do veículo:',
    placeholder: 'Insira a marca do seu veículo',
  },
};

export const extraFields = {
  pix: {
    type: 'text',
    label: 'Chave Pix:',
    placeholder: 'Email, telefone ou aleatória.',
  },
};

export const schema = object({
  carColor: string().required('Preencha esse campo!'),
  carYear: number()
    .test(
      'len',
      'Preencha esse campo corretamente!',
      val => val && val.toString().length === 4
    )
    .max(new Date().getFullYear(), 'Preencha esse campo corretamente!')
    .required('Preencha esse campo!'),
  carModel: string().required('Preencha esse campo!'),
  licensePlate: string().required('Preencha esse campo!'),
  carBrand: string().required('Preencha esse campo!'),
}).required();

export const userSchema = object({
  carColor: string().required('Preencha esse campo!'),
  carYear: number()
    .max(new Date().getFullYear(), 'Preencha esse campo corretamente!')
    .test(
      'len',
      'Preencha esse campo corretamente!',
      val => val && val.toString().length === 4
    )
    .required('Preencha esse campo!'),
  carModel: string().required('Preencha esse campo!'),
  licensePlate: string().required('Preencha esse campo!'),
  carBrand: string().required('Preencha esse campo!'),
  pix: string().required('Preencha esse campo!'),
}).required();
