import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px;
  position: relative;

  ${({ theme }) => theme.styles.cardWidth}
  border-radius: 20px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 544px;
  overflow: hidden;

  > button {
    border-radius: 50px;
    width: 100%;
  }

  > .header {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 0;

    .title {
      color: black;
      text-align: center;
      font-size: 20px;
    }

    .icon {
      margin-left: 15px;
    }
  }

  > .label {
    margin-top: -24px;
    font-size: 18px;

    color: black;
  }

  .route-column {
    width: 80%;

    > p {
      font-size: 14px;

      & + p {
        transform: translateY(16px);
      }
    }
  }
`;

export const HomeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Button = styled(Link)`
  width: 100%;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background: ${({ theme }) => `${theme.colors.primary}`};
  color: ${({ theme, buttonColor }) =>
    buttonColor === 'white' ? `${theme.colors.primary}` : 'white'};
  font-weight: 700;

  opacity: ${({ disabled }) => (disabled ? `0.5` : '1')};
`;

export const ActionButton = styled.button`
  width: 100%;
  height: 50px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  background: ${({ theme }) => `${theme.colors.primary}`};
  color: ${({ theme, buttonColor }) =>
    buttonColor === 'white' ? `${theme.colors.primary}` : 'white'};
  font-weight: 700;

  opacity: ${({ disabled }) => (disabled ? `0.5` : '1')};
`;
