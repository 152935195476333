import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import {
  Disembarkations,
  Embarkations,
  Profile,
  Trips,
  Success,
} from '~/pages/Driver';
import { Home } from '~/pages';
import { Install, AndroidPage, ApplePage } from '~/pages/InstallPwa';
import { getStatus, setStatus } from '~/repositories/status';
import { useAlerts, useSocketContext } from '~/hooks';
import { setTripStorageId } from '~/repositories/trip';
import { getDomain } from '~/utils';
// import { SlideRoutes } from '~/utils';

const Driver = () => {
  const status = getStatus();

  const { socket } = useSocketContext();

  const { newErrorAlert } = useAlerts();

  const navigate = useNavigate();

  useEffect(() => {
    socket.on('in-trip', data => {
      setTripStorageId(data.id);
      if (getStatus() !== 'running') {
        setStatus('running');
        window.location.reload();
      }
    });
    socket.on('accept-trip', data => {
      setStatus('running');
      setTripStorageId(data.id);
      navigate(`/${getDomain()}/embarque`);
      window.location.reload();
    });
    socket.on('disconnect', () => {
      window.location.reload();
    });
    socket.on('error', e => {
      console.log(e);
      newErrorAlert(
        'Algo deu errado, por favor tente novamente mais',
        'socket'
      );
    });
    socket.on('time-out', () => {
      setStatus('home');
      window.location.reload();
    });
  }, []);

  return (
    <Routes>
      {status === 'running' && (
        <Route>
          <Route path="*" element={<Embarkations />} exact />
          <Route
            path={`/${getDomain()}/desembarques`}
            element={<Disembarkations />}
            exact
          />
        </Route>
      )}
      {status === 'success' && <Route path="*" element={<Success />} exact />}

      {status !== 'running' && status !== 'success' && (
        <Route>
          <Route path={`/${getDomain()}/*`} element={<Home />} />
          <Route path={`/${getDomain()}/instalacao`} element={<Install />} />
          <Route
            path={`/${getDomain()}/instalacaoandroid`}
            element={<AndroidPage />}
          />
          <Route
            path={`/${getDomain()}/instalacaoios`}
            element={<ApplePage />}
          />
          <Route path={`/${getDomain()}/perfil`} element={<Profile />} exact />
          <Route
            path={`/${getDomain()}/perfil/phone`}
            element={<Profile.Phone />}
            exact
          />
          <Route
            path={`/${getDomain()}/perfil/email`}
            element={<Profile.Email />}
            exact
          />
          <Route path={`/${getDomain()}/viagens`} element={<Trips />} exact />
        </Route>
      )}
    </Routes>
  );
};

export default Driver;
