/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';

// import { NButton, NTitle, NModalSure } from '~/components';
import { NHeaderWaiting } from '~/components/NHeaderWaiting';

import { NModalSure, Loading } from '~/components';

import { useAlerts, useApi, useApiEffect, useSocketContext } from '~/hooks';

import { getAccountId } from '~/repositories/auth';
import { setStatus } from '~/repositories/status';
import { getTripId } from '~/repositories/trip';
import { getDriverById } from '~/services/drivers';
import { getTripById, incrementSeats } from '~/services/trips';
import { getCorrectTime } from '~/utils/correctTime';

// Imports da tela de acompanhament

import { useJsApiLoader } from '@react-google-maps/api';
import Map from '~/components/Map';

import cancelIcon from '~/assets/icons/cancel-icon.svg';
import carTop from '~/assets/icons/car-top.svg';
import foreEllipse from '~/assets/icons/fore-ellipse.svg';
import whatsappIcon from '~/assets/icons/whatsapp-icon.svg';
import usePersistedState from './hooks/usePersistedState';

// Fim dos imports da tela de acompanhamento

// Import Renderização da pag
import { NFooterWaiting } from '../../../components/NFooterWaiting';

import { App } from './styles';

const Waiting = () => {
  const { newErrorAlert } = useAlerts();
  const [open, setOpen] = useState(false);
  const [waiting, setWaiting] = useState('');
  const [modal, setModal] = useState(false);
  const [cost, setCost] = useState('');
  const [actualTrip, setActualTrip] = useState();
  const [driver, setDriver] = useState();
  const [driverLocation, setDriverLocation] = useState(null);
  const [passengerAddress, setPassengerAddress] = useState();
  const [seats, setSeats] = useState();
  const [finishedTimeout, setFinishedTimeout] = useState(false);
  const [tripLocations, setTripLocations] = useState({
    boar: '',
    land: '',
  });

  const [driveTime, setDriveTime] = useState({
    minutes: '',
    hour: '',
  });
  const [timeUser, setTimeUser] = useState({
    minutes: '',
    hour: '',
  });

  const { request } = useApi();
  const tripId = getTripId();
  const { socket } = useSocketContext();

  const keyStorage = 'driver-location';
  const [driverSaved, setDriverSaved] = usePersistedState(keyStorage, {
    driverLocation: null,
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  useEffect(() => {
    const id = setTimeout(() => {
      setFinishedTimeout(true);
    }, 2000);

    return () => clearTimeout(id);
  }, []);

  const fetchTime = (to, from, setTime) => {
    const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${to.lng},${to.lat};${from.lng},${from.lat}?access_token=${process.env.REACT_APP_MAP_BOX_API_KEY}`;

    try {
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const durationInSeconds = data.routes[0].duration;
          const durationInMinutes = (durationInSeconds / 60).toFixed(0);
          const durationInHours = (durationInMinutes / 60)
            .toFixed(2)
            .toString()
            .split('.');
          const parsedDurationInHours = durationInHours[1] % 60;

          const currentTime = new Date();
          const estimatedArrivalTime = new Date(
            currentTime.getTime() + durationInSeconds * 1000
          );
          const formattedEstimatedTime = `${estimatedArrivalTime.getHours()}:${String(
            estimatedArrivalTime.getMinutes()
          ).padStart(2, '0')}`;

          setTime({
            time:
              durationInMinutes <= 59
                ? `${durationInMinutes}min`
                : `${durationInHours[0]}:${
                    parsedDurationInHours.toFixed(0).length === 1
                      ? `0${parsedDurationInHours.toFixed(0)}`
                      : parsedDurationInHours.toFixed(0)
                  }min`,
            hour: formattedEstimatedTime,
          });

          setDriveTime({
            time:
              durationInMinutes <= 59
                ? `${durationInMinutes}min`
                : `${durationInHours[0]}:${
                    parsedDurationInHours.toFixed(0).length === 1
                      ? `0${parsedDurationInHours.toFixed(0)}`
                      : parsedDurationInHours.toFixed(0)
                  }min`,
            hour: formattedEstimatedTime,
          });
        })
        .catch(err => {
          console.log(err);
        });
    } catch {
      setTime('Aconteceu um erro');
    }
  };

  useEffect(() => {
    if (passengerAddress) {
      const to = {
        lng: passengerAddress.boardingCoordinates.lng,
        lat: passengerAddress.boardingCoordinates.lat,
      };
      const from = {
        lng: passengerAddress.landingCoordinates.lng,
        lat: passengerAddress.landingCoordinates.lat,
      };
      fetchTime(to, from, setTimeUser);
    }
  }, [passengerAddress]);

  const handleFetchTime = useCallback(async () => {
    if (driver) {
      const to = {
        lng: passengerAddress.boardingCoordinates.lng,
        lat: passengerAddress.boardingCoordinates.lat,
      };
      const driveLoc = {
        lng: driverSaved.driverLocation.longitude,
        lat: driverSaved.driverLocation.latitude,
      };
      await fetchTime(to, driveLoc, setDriveTime);
    }
  }, [passengerAddress, driverSaved, driver]);

  useEffect(() => {
    handleFetchTime();
  }, [handleFetchTime]);

  const changeWaiting = isDriver => {
    if (isDriver) setWaiting('Buscando...');
    else setWaiting('Aguardando Motorista.');
  };

  const handleButtonClickModal = () => {
    setModal(true);
  };

  const attData = useCallback(data => {
    setActualTrip(data);

    const id = getAccountId();

    const passenger = data.passengers.filter(pass => pass.passenger.id === id);
    setPassengerAddress({
      boardingCoordinates: {
        lat: passenger[0].boardingAddress.latitude,
        lng: passenger[0].boardingAddress.longitude,
        name: passenger[0].boardingAddress.name,
      },
      landingCoordinates: {
        lat: passenger[0].landingAddress.latitude,
        lng: passenger[0].landingAddress.longitude,
        name: passenger[0].landingAddress.name,
      },
    });

    setCost(passenger[0].cost);

    // setAdd(passenger[0].boardingAddress.name.slice());

    setTripLocations({
      boar: passenger[0].boardingAddress.name,
      land: passenger[0].landingAddress.name,
    });

    setSeats(data.passengers[0].seats);

    if (data.status === 'waiting_passengers') changeWaiting(false);
    else if (data.status === 'waiting_driver') changeWaiting(true);
    if (data.driver) {
      setDriver(data?.driver);
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    socket.on('update-location', data => {
      setDriverSaved({
        driverLocation: {
          ...data,
        },
      });
    });
    socket.on('update-available-trips', data => {
      if (data.id === tripId)
        request(
          () => getTripById(tripId),
          response => {
            attData(response.data);
            fetchTime();
          }
        );
    });

    socket.on('searching-driver', () => {
      changeWaiting(true);
    });

    socket.on('passenger-cancel', data => {
      if (data.id === getAccountId()) {
        setStatus('home');
        localStorage.removeItem(keyStorage);
        setDriverSaved({
          driverLocation: null,
        });
        window.location.reload();
      }
      if (!open) changeWaiting(false);
    });
    socket.on('driver-cancel', () => {
      localStorage.removeItem(keyStorage);
      setDriverSaved({
        driverLocation: null,
      });
      newErrorAlert('O motorista desistiu da viagem');
      changeWaiting(false);
      window.location.reload();
    });
    socket.on('accept-trip', () => {
      setOpen(true);
      window.location.reload();
    });
    socket.on('passenger-boarding', () => {
      setStatus('running');
      window.location.reload();
    });
    socket.on('passenger-landing', () => {
      setStatus('success');
      window.location.reload();
    });
    socket.on('no-trip', () => {
      setStatus('home');
      window.location.reload();
    });
  }, []);

  useApiEffect(
    () => getTripById(tripId),
    response => {
      attData(response.data);
      fetchTime();
    }
  );

  const onCancel = () => {
    socket.emit('passenger-cancel');
  };

  const message = () => {
    if (driver)
      request(
        () => getDriverById(driver.id),
        response => {
          if (response.data.phone)
            window.open(`https://wa.me/${response.data.phone}`);
          else
            newErrorAlert('Ops! tivemos um problema com o número do motorista');
        }
      );
    else
      newErrorAlert('Ops! tivemos um problema com as informações do motorista');
  };
  const handleConfirm = () => {
    onCancel();
    localStorage.removeItem(keyStorage);
    setDriverSaved({
      driverLocation: null,
    });
  };

  const handleIncreaseSeats = () => {
    const incrementSeat = seats + 1;

    incrementSeats(incrementSeat, actualTrip.id);

    setSeats(incrementSeat);

    socket.on('searching-driver', () => {
      changeWaiting(true);
      window.location.reload();
    });
  };

  return (
    <App>
      {!finishedTimeout ? (
        <Loading />
      ) : (
        <>
          <NHeaderWaiting
            driver={driver}
            waiting={waiting}
            tripLocations={tripLocations}
            actualTrip={actualTrip}
          />

          {isLoaded && passengerAddress && (
            <Map
              actualTrip={actualTrip}
              carTop={carTop}
              driver={driver}
              foreEllipse={foreEllipse}
              passengerAddress={passengerAddress}
              driverLocation={driverSaved.driverLocation}
              key="Mapa"
            />
          )}

          <NFooterWaiting
            driver={driver}
            actualTrip={actualTrip}
            waiting={waiting}
            cost={cost}
            open={open}
            driveTime={driveTime}
            message={message}
            timeUser={timeUser}
            handleButtonClickModal={handleButtonClickModal}
            handleIncreaseSeats={handleIncreaseSeats}
          />
        </>
      )}

      <NModalSure
        visible={modal}
        label="Tem certeza que quer desistir dessa viagem?"
        onCancel={() => setModal(false)}
        onConfirm={handleConfirm}
        onClose={() => setModal(false)}
      />
    </App>
  );
};

export default Waiting;
