import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 0 20px;

  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.light_gray};

  display: flex;
  flex-direction: column;
  align-items: center;

  .div_top {
    display: flex;
    flex-direction: row;
    z-index: 10;
    justify-content: space-between;
    align-items: center;

    width: 100vw;

    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0 20px 0 20px;

    > .title {
      margin-top: 20px;
    }
  }

  .div_return {
    display: flex;
    z-index: 10;
    justify-content: center;
    margin-top: 1.75rem;
    width: 100vw;

    button {
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 10px 17px 10px 17px;
      border-radius: 25px;
      border: 2px solid white;
    }

    .able {
      color: #944bbb;
      border-color: transparent;
      background-color: white;
    }

    .disable {
      color: white;
    }
  }

  .nsearch {
    z-index: 11;
  }

  .butonConfir {
    background-color: #ffffff;
    color: #000000;
    margin-top: 20px;
  }

  .map {
    margin: 20px 0;

    ${({ theme }) => theme.styles.itemWidth}
    height: 500px;
    width: 100vw;
  }
`;

export const Teste = styled.div`
  width: 100%;
  background-color: red;
  height: 10px;
`;
