import { useEffect, useRef, useState } from 'react';

import { getDistrictById } from '~/services/districts';

const usePlaces = (districtId, getCurrentLocation) => {
  const searchRef = useRef();
  const mapRef = useRef();

  const [place, setPlace] = useState('');
  const [coordinates, setCoordinates] = useState();
  const [isMapLoading, setIsMapLoading] = useState(false);

  useEffect(() => {
    const request = async () => {
      const response = districtId && (await getDistrictById(districtId));

      const boundry = {
        latitude: Number(response?.data?.boundry?.latitude),
        longitude: Number(response?.data?.boundry?.longitude),
        radius: Number(response?.data?.boundry?.radius),
      };

      const bounds = {
        north: boundry.latitude + boundry.radius / 100,
        south: boundry.latitude - boundry.radius / 100,
        east: boundry.longitude + boundry.radius / 100,
        west: boundry.longitude - boundry.radius / 100,
      };

      const autocomplete = new window.google.maps.places.Autocomplete(
        searchRef.current,
        {
          fields: ['formatted_address', 'geometry', 'name'],
          componentRestrictions: { country: 'br' },
          ...(boundry.radius
            ? {
                bounds,
                strictBounds: true,
              }
            : {}),
        }
      );

      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: -9.8306521515871, lng: -51.07389452170478 },
        zoom: 4,
        mapTypeControl: false,
      });

      map.addListener('center_changed', () => {
        setIsMapLoading(true);
        setTimeout(() => {
          setIsMapLoading(false);
        }, 500);
      });

      const marker = new window.google.maps.Marker({
        map,
        anchorPoint: new window.google.maps.Point(0, -29),
      });

      if (navigator.geolocation) {
        if (getCurrentLocation) {
          navigator.geolocation.getCurrentPosition(pos => {
            const geocoder = new window.google.maps.Geocoder();
            geocoder
              .geocode({
                location: {
                  lat: pos.coords.latitude,
                  lng: pos.coords.longitude,
                },
              })
              // eslint-disable-next-line consistent-return
              .then(geoResponse => {
                const address = geoResponse.results[0];

                if (!address.geometry || !address.geometry.location) {
                  return window.alert('Localização não encontrada.');
                }

                if (address.geometry.viewport) {
                  map.fitBounds(address.geometry.viewport);
                } else {
                  map.setCenter(address.geometry.location);
                  map.setZoom(23);
                }

                marker.setPosition(address.geometry.location);
                marker.setVisible(true);
                setPlace(address.formatted_address);
                setCoordinates({
                  lat: address.geometry.location.lat(),
                  long: address.geometry.location.lng(),
                });
                searchRef.current.value = address.formatted_address;
              })
              .catch(e => window.alert(`Geocoder failed due to: ${e}`));
          });
        }
      } else {
        console.log('Geolocation is not supported by this browser.');
      }

      autocomplete.addListener('place_changed', () => {
        const selectedPlace = autocomplete.getPlace();

        if (!selectedPlace.geometry || !selectedPlace.geometry.location) {
          window.alert('Localização não encontrada.');

          return;
        }

        if (selectedPlace.geometry.viewport) {
          map.fitBounds(selectedPlace.geometry.viewport);
        } else {
          map.setCenter(selectedPlace.geometry.location);
          map.setZoom(23);
        }

        marker.setPosition(selectedPlace.geometry.location);
        marker.setVisible(true);

        setPlace(selectedPlace.formatted_address);
        setCoordinates({
          lat: selectedPlace.geometry.location.lat(),
          long: selectedPlace.geometry.location.lng(),
        });
      });
    };

    request();
  }, [getCurrentLocation, searchRef]);

  return { place, searchRef, mapRef, coordinates, isMapLoading };
};

export default usePlaces;
