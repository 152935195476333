import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { FaEdit } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { NButton, NInput, NModalSure, NTitle, NPicture } from '~/components';
import { useApi, useAuth } from '~/hooks';
import { removeAuth } from '~/repositories/auth';
import { deletePassenger, updatePassenger } from '~/services/passengers/index';
import {
  confirmEmailKey,
  confirmPhoneKey,
  validateEmail,
  validatePhone,
} from '~/services/users';
import { getDomain } from '~/utils';

import Confirm from '../../Auth/Register/Confirm';
import FormCar from '../../Auth/Register/FormCar';

import { Container } from './styles';

const Profile = () => {
  const { register, handleSubmit, control } = useForm();

  const navigate = useNavigate();

  const [modal, setModal] = useState(false);
  const [edit, setEdit] = useState(false);

  const { user, setUser } = useAuth();
  const { request } = useApi();

  const onSubmit = data => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });

    request(
      () => updatePassenger(formData),
      response => {
        console.log(response);
        setUser(response.data.passenger);
        setEdit(false);
        return 'Informações alteradas com sucesso!';
      }
    );
  };

  const onExclude = () => {
    request(
      () => deletePassenger(),
      () => {
        removeAuth();
        window.location.reload();
        return 'Conta deletada com sucesso';
      }
    );
  };

  return (
    <Container>
      <NTitle
        label="Perfil"
        className="title"
        onBack={() => navigate(`/${getDomain()}/`)}
      />
      <FaEdit size={30} className="icon_edit" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="photoImage"
          render={({ field }) => (
            <NPicture id="photoImage" imgDefault={user.photoImage} {...field} />
          )}
        />

        <NInput
          label="Nome"
          placeholder={user.name}
          {...register('name')}
          disabled={!edit}
        />

        <Link to={`/${getDomain()}/perfil/email`}>
          <NInput label="Email" placeholder={user.email} disabled />
        </Link>

        <Link to={`/${getDomain()}/perfil/phone`}>
          <NInput label="Telefone" placeholder={user.phone} disabled />
        </Link>

        <p>{modal}</p>

        {edit && <NButton type="submit" label="Salvar alterações" />}
      </form>
      {!edit && (
        <NButton
          type="button"
          label="Editar alterações"
          onClick={() => {
            setEdit(prev => !prev);
          }}
        />
      )}

      {/*
      <NButton
        label="Excluir conta"
        className="btn_exclude"
        color="red"
        onClick={() => setModal(true)}
      />
      */}

      <NModalSure
        visible={modal}
        label="Tem certeza que deseja excluir sua conta?"
        onCancel={() => setModal(false)}
        onConfirm={() => onExclude()}
        confirmLabel="Excluir"
        cancelLabel="Cancelar"
        onClose={() => setModal(false)}
      />
    </Container>
  );
};

Profile.Phone = () => {
  const update = key => updatePassenger({ phoneKey: key });

  return (
    <Confirm
      title="Verificar número"
      type="SMS"
      name="phone"
      sendService={validatePhone}
      confirmService={confirmPhoneKey}
      updateService={update}
    />
  );
};

Profile.Email = () => {
  const update = key => updatePassenger({ emailKey: key });

  return (
    <Confirm
      title="Verificar email"
      type="Email"
      name="email"
      sendService={validateEmail}
      confirmService={confirmEmailKey}
      updateService={update}
    />
  );
};

Profile.FormCar = () => <FormCar />;

export default Profile;
