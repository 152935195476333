import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdContentCopy } from 'react-icons/md';

import { getTripById } from '~/services/trips';
import { getTripId } from '~/repositories/trip';
import { getAccountId } from '~/repositories/auth';

import { useApiEffect } from '~/hooks';
import { setStatus } from '~/repositories/status';

import { copyText } from '~/utils/copyText';

import badIcon from '~/assets/icons/bad_face.png';
import happyIcon from '~/assets/icons/happy_face.png';
import neutralIcon from '~/assets/icons/neutral_face.png';

import { NButton, NButtonLink } from '~/components';

import { rateTrip } from '~/services/passengers';
import { getDomain } from '~/utils';
import { Container, Wrapper, RateContainer } from './styles';

const TRIP_PASSENGER_RATING = {
  AMAZING: 10,
  GOOD: 5,
  BAD: -5,
  NOT_RATED: 0,
  COMPLAINT: -10,
};

const Success = () => {
  const [cost, setCost] = useState('');
  const [pix, setPix] = useState('');

  const [currentRate, setCurrentRate] = useState(0);
  const [comment, setComment] = useState('');

  const navigate = useNavigate();

  const tripId = getTripId();

  const attData = data => {
    const id = getAccountId();

    const passenger = data.passengers.filter(pass => pass.passenger.id === id);

    setCost(passenger[0].cost);
    setPix(data.driver.pix);
  };

  const goHome = async () => {
    const id = getAccountId();

    await rateTrip(id, currentRate, comment);

    setStatus('home');
    navigate(`/${getDomain()}/`, { state: {} });
    window.location.reload();
  };

  const handleFinishTrip = () => {
    if (currentRate === 0) {
      setCurrentRate(TRIP_PASSENGER_RATING.NOT_RATED);
      goHome();
    }
    if (
      [
        TRIP_PASSENGER_RATING.AMAZING,
        TRIP_PASSENGER_RATING.GOOD,
        TRIP_PASSENGER_RATING.BAD,
        TRIP_PASSENGER_RATING.COMPLAINT,
      ].includes(currentRate)
    ) {
      goHome();
    }
  };

  useEffect(() => {
    if (currentRate === TRIP_PASSENGER_RATING.COMPLAINT) handleFinishTrip();
  }, [currentRate]);

  useApiEffect(
    () => getTripById(tripId),
    response => attData(response.data)
  );

  return (
    <Container>
      <p className="subtitle trip-finished">Viagem finalizada com sucesso</p>

      <div className="money_div">
        <p className="money_label">{cost}</p>
      </div>

      <p className="subtitle">Pague o seu motorista!</p>

      <p>Chave pix do motorista</p>
      <Wrapper className="wrapper" onClick={() => copyText(pix)}>
        <p className="content">{pix}</p>
        <MdContentCopy size={25} className="icon" />
      </Wrapper>

      <RateContainer>
        <button
          type="button"
          onClick={() => setCurrentRate(TRIP_PASSENGER_RATING.AMAZING)}
        >
          <img
            className="rate-icon"
            alt="amazing"
            src={happyIcon}
            style={{
              opacity: currentRate === TRIP_PASSENGER_RATING.AMAZING ? 1 : 0.5,
            }}
          />
          <span>Ótimo</span>
        </button>
        <button
          type="button"
          onClick={() => setCurrentRate(TRIP_PASSENGER_RATING.GOOD)}
        >
          <img
            className="rate-icon"
            alt="good"
            src={neutralIcon}
            style={{
              opacity: currentRate === TRIP_PASSENGER_RATING.GOOD ? 1 : 0.5,
            }}
          />
          <span>Bom</span>
        </button>
        <button
          type="button"
          onClick={() => setCurrentRate(TRIP_PASSENGER_RATING.BAD)}
        >
          <img
            className="rate-icon"
            alt="bad"
            src={badIcon}
            style={{
              opacity: currentRate === TRIP_PASSENGER_RATING.BAD ? 1 : 0.5,
            }}
          />
          <span>Ruim</span>
        </button>
      </RateContainer>
      <textarea
        className="comment"
        placeholder="Algum comentário? (Opcional)"
        onChange={e => setComment(e.target.value)}
      />
      <NButton label="Avaliar" onClick={() => handleFinishTrip()} />
      <NButtonLink
        label="Denunciar"
        textColor="#ff0000"
        onClick={() => setCurrentRate(TRIP_PASSENGER_RATING.COMPLAINT)}
      />
    </Container>
  );
};

export default Success;
