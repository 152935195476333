/* eslint-disable radix */

import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { NTitle } from '~/components';
import { createDriver } from '~/services/drivers';
import { createPassenger } from '~/services/passengers';

import {
  confirmEmailKey,
  confirmPhoneKey,
  validateEmail,
  validatePhone,
} from '~/services/users';

import { getDomain } from '~/utils';
import { Container } from './styles';
import Form from './Form';
import Confirm from './Confirm';
import Success from './Success';
import FormCar from './FormCar';

const Register = () => (
  <Container>
    <NTitle label="Cadastro" />
    <Form />
  </Container>
);

Register.FormCar = FormCar;

Register.Phone = () => {
  const { state } = useLocation();

  const createService = phoneKey => {
    const { reqData } = state;

    reqData.cpf = reqData.cpf.replace(/\D/g, '');

    if (state.type === 'passenger') {
      return createPassenger({ ...reqData, phoneKey });
    }

    const formData = new FormData();

    Object.entries(reqData).forEach(([key, value]) =>
      formData.append(key, value)
    );

    formData.append('phoneKey', phoneKey);
    return createDriver(formData);
  };

  return (
    <Confirm
      title="Verificar número"
      type="SMS"
      name="phone"
      sendService={validatePhone}
      confirmService={confirmPhoneKey}
      createService={createService}
    />
  );
};

Register.Email = () => {
  const { state } = useLocation();

  const createService = emailKey => {
    const { reqData, phoneKey } = state;

    reqData.cpf = reqData.cpf.replace(/\D/g, '');

    if (state.type === 'passenger') {
      return createPassenger({ ...reqData, phoneKey, emailKey });
    }

    const formData = new FormData();

    Object.entries(reqData).forEach(([key, value]) =>
      formData.append(key, value)
    );

    formData.append('phoneKey', phoneKey);
    formData.append('emailKey', emailKey);

    return createDriver(formData);
  };

  return (
    <Confirm
      title="Verificar email"
      type="email"
      name="email"
      sendService={validateEmail}
      confirmService={confirmEmailKey}
      createService={createService}
    />
  );
};

Register.Success = () => {
  const navigate = useNavigate();

  return <Success redirect={() => navigate(`/${getDomain()}`)} />;
};

export default Register;
