import React, { useEffect, useState } from 'react';

import { MdContentCopy } from 'react-icons/md';

import { getTripById } from '~/services/trips';
import { getTripId } from '~/repositories/trip';
import { getAccountId } from '~/repositories/auth';

import { useApiEffect, useSocketContext } from '~/hooks';
import { setStatus } from '~/repositories/status';

import { copyText } from '~/utils/copyText';
import { ReactComponent as CarIcon } from '~/assets/icons/car.svg';
import { ReactComponent as MoneyIcon } from '~/assets/icons/money.svg';

import { Container, Wrapper } from './styles';

const InTrip = () => {
  const [cost, setCost] = useState('');
  const [pix, setPix] = useState('');

  const tripId = getTripId();

  const { socket } = useSocketContext();

  const attData = data => {
    const id = getAccountId();

    const passenger = data.passengers.filter(pass => pass.passenger.id === id);

    setCost(passenger[0].cost);
    setPix(data.driver.pix);
  };

  useEffect(() => {
    socket.on('passenger-landing', () => {
      setStatus('success');
      window.location.reload();
    });
    socket.on('no-trip', () => {
      setStatus('home');
      window.location.reload();
    });
  }, []);

  useApiEffect(
    () => getTripById(tripId),
    response => attData(response.data)
  );

  return (
    <Container>
      {/* <p className="title_top">Vá com Deus!</p> */}
      <div className="div_info">
        <CarIcon className="icon_car" />
        <p className="title">Você está em viagem.</p>
        <div className="money">
          <MoneyIcon className="money_icon" />
          <p className="money_value">{cost}</p>
          <MoneyIcon className="money_icon" />
        </div>
        <p className="subtitle">Não perca tempo. Pague agora!</p>
      </div>
      <p>Chave pix do motorista</p>
      <Wrapper className="wrapper" onClick={() => copyText(pix)}>
        <p className="content">{pix}</p>
        <MdContentCopy size={25} className="icon" />
      </Wrapper>
    </Container>
  );
};

export default InTrip;
