import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.light_gray};

  display: flex;
  flex-direction: column;
  align-items: center;

  .title_warning {
    z-index: 5;
    color: white;
  }

  .btn_teste {
    display: none;
  }

  .div_top {
    display: flex;
    flex-direction: row;
    z-index: 10;
    justify-content: space-between;
    align-items: center;

    p {
      color: white;
    }
    width: 100vw;

    margin-top: 50px;
    margin-bottom: 30px;
    padding: 0 20px 0 20px;

    > .title,
    .title_viagens {
      margin-top: 20px;
    }

    > .title_viagens {
      margin-bottom: 20px;
    }

    > .title_logo {
      width: 100%;
      margin-top: 12px;
      font-size: 24px;
      text-align: center;
    }

    .div_economy {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .economy_title {
        font-size: 15px;
        margin-bottom: 5px;
      }

      .div_money_time {
        font-size: 18px;
        border-style: dashed;
        border-color: white;
        padding: 15px 25px 15px 25px;
        border-radius: 20px;
      }
    }

    .icon_help {
      color: white;
    }
  }

  .card {
    z-index: 5;
    margin-bottom: 25px;
  }

  .nsearch {
    margin-bottom: 25px;
  }
`;

export const DriverBody = styled.div`
  width: 90%;
  max-width: 500px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .row {
    display: flex;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 120px;

    span {
      font-size: 12px;
    }

    h2 {
      font-size: 20px;
      margin-top: 4px;
    }
  }

  .divider {
    width: 90%;
    height: 1px;
    background: #4d4d4d;
    margin: 16px 0;
    max-width: 200px;
  }

  .div_next {
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 20px;

    gap: 15px;

    .advanced {
      font-size: 30px;
    }
  }
`;

export const GainsContainer = styled.div`
  background: white;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 24px;

  strong {
    font-size: 12px;
    margin-bottom: 8px;
  }

  > .row {
    > img {
      margin-right: 12px;
    }
    > h1 {
      font-size: 28px;
    }
  }

  .gains {
    gap: 0px;
  }

  .separator {
    background: #4d4d4d;
    width: 1px;
  }
`;

export const DriverId = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  margin-top: 20px;
  padding: 8px 24px;
  border-radius: 10px;
  margin-bottom: 24px;
  width: 100%;

  p {
    margin-right: 16px;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
  }
`;
