import { getAccountId } from '~/repositories/auth';
import { api } from '~/services/api';

export const createPassenger = data => api.post('/passengers', data);

export const verifyPassengerRegister = data =>
  api.post('/passengers/verify-register', data);

export const getPassenger = () => api.get(`/passengers/${getAccountId()}`);

export const getPassengerById = id => api.get(`/passengers/${id}`);

export const updatePassenger = data =>
  api.put(`/passengers/${getAccountId()}`, data);

export const deletePassenger = () =>
  api.delete(`/passengers/${getAccountId()}`);

export const getTripsHistory = page =>
  api.get(`/passengers/${getAccountId()}/trips?page=${page}`);

export const rateTrip = (id, rate, comment) => {
  const body =
    comment === ''
      ? {
          passengerRating: rate,
        }
      : {
          passengerRating: rate,
          passengerComment: comment,
        };

  api.put(`/passengers/${id}/rate-trip`, body);
};
