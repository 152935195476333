import { Route, Routes, useLocation } from 'react-router-dom';
import { NotFound } from '~/pages';

import { Login, RecoverPassword, Register } from '~/pages/Auth';
import { AndroidPage, ApplePage, Install } from '~/pages/InstallPwa';
import { getDomain } from '~/utils';

const Auth = () => {
  const { state } = useLocation();

  return (
    <Routes>
      <Route path={`/${getDomain()}/`} element={<Login />} />

      <Route path={`/${getDomain()}/instalacao`} exact element={<Install />} />
      <Route
        path={`/${getDomain()}/instalacaoandroid`}
        exact
        element={<AndroidPage />}
      />
      <Route
        path={`/${getDomain()}/instalacaoios`}
        exact
        element={<ApplePage />}
      />

      <Route path={`/${getDomain()}/cadastro`} element={<Register />} exact />
      <Route
        path={`/${getDomain()}/password_recover`}
        element={<RecoverPassword />}
        exact
      />
      <Route
        path={`/${getDomain()}/cadastro/veiculo`}
        element={<Register.FormCar />}
        exact
      />
      {state?.phoneConfirmation && (
        <Route
          path={`/${getDomain()}/cadastro/telefone`}
          element={<Register.Phone />}
          exact
        />
      )}
      {state?.phoneKey && (
        <Route
          path={`/${getDomain()}/cadastro/email`}
          element={<Register.Email />}
          exact
        />
      )}
      {state?.success && (
        <Route
          path={`/${getDomain()}/cadastro/sucesso`}
          element={<Register.Success />}
          exact
        />
      )}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Auth;
