import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDomain } from '~/utils';

import { NButton } from '~/components';
import { Container } from './styles';

const NotFound = () => {
  const navigate = useNavigate();

  const disembarkationTripId = localStorage.getItem('desembarque');
  const tripUrlSplitted = window.location.href.split('/viagens');

  useEffect(() => {
    if (disembarkationTripId) {
      navigate(`/${getDomain()}/`, {
        state: {
          disembarkationUrl: `/${getDomain()}/desembarque/${disembarkationTripId}`,
        },
      });
    } else if (tripUrlSplitted[1]) {
      navigate(`/${getDomain()}/`, {
        state: {
          tripUrl: `/${getDomain()}/viagens${tripUrlSplitted[1]}`,
        },
      });
    }
  }, []);

  return (
    <Container>
      <NButton
        type="button"
        label="Continuar"
        color="white"
        onClick={() => navigate(`/${getDomain()}/`)}
      />
    </Container>
  );
};

export default NotFound;
