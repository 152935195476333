import styled from 'styled-components';

export const ContainerModal = styled.div`
  @media only screen and (max-width: 1125px) and (max-height: 2001px) {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @media only screen and (max-device-height: 680px) {
      width: 100%;
      height: 100%;
      margin-top: -19%;
    }
  }
  display: none;
`;
export const ModalContainer = styled.div`
  width: 100%;
  height: 50%;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  justify-content: center;
  align-items: center;
  margin-top: 80%;
  @media only screen and (max-device-height: 680px) {
    margin-top: 60%;
    height: 60%;
  }
`;
export const Title1 = styled.h1`
  font-size: 27px;
  text-align: center;
  font-family: 'Comfortaa', cursive;
  font-weight: 400;
  margin-top: 8%;
  max-width: 80%;
  margin-left: 10%;
`;

export const DivIcon = styled.img`
  width: 40%;
  height: 40%;
  align-items: center;
  transform: rotate(180deg);
  margin-left: 30%;
  margin-top: 7%;
`;

export const Button = styled.button`
  background-color: #944bbb;
  height: 65px;
  font-family: 'Comfortaa', cursive;
  padding: 5%;
  width: 80%;
  border-radius: 10px;
  color: white;
  font-size: 20px;
  border: none;
  font-weight: 500;
  margin-left: 10%;
`;

export const CloseBnt = styled.button`
  border: none;
  margin-top: 7%;
  font-size: 20px;
  font-family: 'Comfortaa', cursive;
  margin-left: 34%;
  @media only screen and (max-device-height: 680px) {
    margin-top: 5%;
  }
`;

export const CloseText = styled.h1`
  color: #4d4d4d;
  text-decoration: underline;
`;
