import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const Title = styled.h1`
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-size: 27px;
  margin-top: 50%;
  margin-bottom: 20%;
`;
export const Button = styled.button`
  background: #944bbb;
  border-radius: 10px;
  width: 80%;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 15%;
  padding-right: 15%;
  margin-bottom: 10%;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  color: white;
`;
export const ButtonLink = styled.a`
  background: #944bbb;
  border-radius: 10px;
  width: 80%;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 15%;
  padding-right: 15%;
  margin-bottom: 10%;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  color: white;
  text-decoration: none;
`;
export const DivArrow = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 6%;
  margin-left: 8%;
`;
