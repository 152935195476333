import { api } from '~/services/api';

export const validatePhone = phone =>
  api.post('/users/validate/phone', { phone });

export const validateEmail = email =>
  api.post('/users/validate/email', { email });

export const confirmPhoneKey = key => api.post(`/users/validate/phone/${key}/`);

export const confirmEmailKey = key => api.post(`/users/validate/email/${key}/`);

export const passwordRecover = data =>
  api.post(`/users/password-recover/`, data);

export const passwordRecoverToken = key =>
  api.post(`/users/password-recover/${key}`);
