import React, { useState } from 'react';

import { Container } from './styles';
import NButton from '../NButton';
import { NInput } from '..';

const NModalOrderReady = ({
  visible,
  onConfirm = () => null,
  onClose = () => null,
  ...rest
}) => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const [phoneError, setPhoneError] = useState(false);

  return (
    <Container visible={visible} {...rest} onClose={onClose}>
      <p className="label">
        Avise seu cliente que o pedido dele já está pronto e aguarde ele
        solicitar a entrega no nosso sistema
      </p>

      <div className="input-label-wrapper">
        <p className="input-label">Nome do cliente: (Opcional)</p>

        <NInput
          placeholder="Nome"
          variant="light"
          onChange={e => setName(e.target.value)}
          style={{
            height: 50,
            width: '70%',
            padding: '0 16px',
            borderRadius: 8,
          }}
        />
      </div>

      <div className="input-label-wrapper">
        <p className="input-label">Telefone do cliente:</p>

        <NInput
          placeholder="Telefone"
          variant="light"
          mask="+55 (99) 99999-9999"
          onChange={e => setPhone(e.target.value)}
          error={phoneError}
          style={{
            height: 50,
            width: '70%',
            padding: '0 16px',
            borderRadius: 8,
          }}
        />
      </div>

      <NButton
        className="confirm-button"
        color="gray"
        onClick={() => {
          const trimmedPhone = phone.replace(/\D/g, '');

          if (trimmedPhone.length === 0) {
            setPhoneError('Campo obrigatório');
            return;
          }
          if (trimmedPhone.length < 13) {
            setPhoneError('Telefone inválido');
            return;
          }

          setPhoneError(false);
          onClose();

          onConfirm({
            phone: trimmedPhone,
            name,
          });
          window.alert('O cliente foi avisado com sucesso!');

          setPhone('');
          setName('');
          onClose();
        }}
        label="Enviar"
      />
    </Container>
  );
};

export default NModalOrderReady;
