const getDomain = () => {
  const url = window.location.href;

  const isDev = url.includes('localhost:3000')
    ? 'localhost:3000/'
    : 'weptek.app/';

  const matches = url?.split(isDev)[1]?.split('/')[0];
  if (!matches) {
    const dev = url.split(':')[2].split('/')[1];
    return dev;
  }

  return matches || 'whatscar';
};

export default getDomain;
