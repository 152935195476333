import styled from 'styled-components';
import { NButton, NTitle } from '~/components';

export const Container = styled.div`
  width: 100vw;
  height: 100%;

  padding-bottom: 30px;

  display: flex;
  flex-direction: column;

  > .ntitle {
    position: absolute;
    top: 30px;
  }
`;

export const Title = styled(NTitle)`
  position: absolute;
  top: 30px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  min-height: 100vh;
`;

export const ContentImage = styled.img``;

export const ContentTitle = styled.h1`
  text-align: center;
  font-size: 24px;
  max-width: 273px;
  margin-top: 50px;
`;

export const ContentParagraph = styled.p`
  text-align: center;
  font-size: 18px;
  max-width: 360px;
  margin-top: 18px;
`;

export const ContentButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
`;

export const ContentButton = styled(NButton)`
  &.download {
    border: 1px solid #4d4d4d;
    box-shadow: none;
  }

  &.open {
    box-shadow: none;
  }
`;
