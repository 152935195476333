import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Link } from 'react-router-dom';

import { NButton, NInput, NModalSure } from '~/components';
import { useApi } from '~/hooks';
import { removeAuth, setAuth } from '~/repositories/auth';
import { login } from '~/services/auth';

import { getDriver } from '~/services/drivers';
import schema from './schema';
import { Container } from './styles';

const Form = ({ recover }) => {
  const { register, handleSubmit, setError, formState } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const { request } = useApi();

  const [modal, setModal] = useState();
  const [listData, setListDate] = useState();

  const onSubmit = data => {
    request(
      () =>
        login({
          phone: `55${data.phone.replace(/\D/g, '')}`,
          password: data.password,
        }),
      async response => {
        const session = response.data;
        const isLicensed = session.find(s => s.accountType === 'licensed');

        if (isLicensed) {
          const registeredAsPassenger = session.find(
            s => s.accountType === 'passenger'
          );

          if (!registeredAsPassenger) {
            // eslint-disable-next-line no-alert
            alert(
              'Olá licenciado! Parece que você ainda não se registrou como passageiro, para poder utilizar a conta, cadastre-se como passageiro utilizando os mesmos dados usados no painel administrativo!'
            );
          }

          const registeredAsDriver = session.find(
            s => s.accountType === 'driver'
          );

          if (!registeredAsDriver) {
            // eslint-disable-next-line no-alert
            alert(
              'Olá licenciado! Parece que você ainda não se registrou como motorista ou ainda não ativou sua conta no painel administrativo'
            );
          }
        }

        setListDate(session);

        if (session.length > 1) setModal(true);
        else {
          setAuth({
            ...session[0],
            isPassenger: session[0].accountType === 'passenger',
          });

          window.location.reload();
        }
      },
      response => {
        if (response.status === 401) {
          setError('password', { message: 'Telefone ou senha inválidos!' });

          return true;
        }
        if (response.status === 403) {
          setError('password', {
            message: 'Como motorista você ainda não foi autorizado!',
          });

          return true;
        }
        return false;
      }
    );
  };

  const onLogin = async passageiro => {
    if (passageiro) {
      const passengerSession = listData.find(
        data => data.accountType === 'passenger'
      );

      setAuth({
        ...passengerSession,
        isPassenger: true,
      });
    } else {
      const driverSession = listData.find(
        data => data.accountType === 'driver'
      );

      setAuth({
        ...driverSession,
        isPassenger: false,
      });

      await request(
        () => getDriver(),
        getDriverResponse => {
          const isDriverActive = getDriverResponse.data.active;
          if (!isDriverActive) {
            alert('Sua conta ainda não está ativa');
            removeAuth();
          }
        }
      );
    }

    window.location.reload();
  };

  return (
    <Container onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <NInput
        label="Telefone"
        mask="(99) 99999-9999"
        placeholder="Digite o seu telefone aqui."
        error={formState.errors.phone?.message}
        {...register('phone', { required: true })}
      />
      <NInput
        type="password"
        label="Senha"
        error={formState.errors.password?.message}
        placeholder="Insira a senha da sua conta"
        {...register('password', { required: true })}
      />
      <NButton type="submit" label="Entrar" className="button" />
      <Link to={recover}>RECUPERAR SENHA</Link>

      <NModalSure
        visible={modal}
        label="Com qual conta deseja entrar?"
        onCancel={() => onLogin(false)}
        confirmLabel="Passageiro/estabelecimento"
        // cancelLabel="Motorista/entregador"
        onConfirm={() => onLogin(true)}
        onClose={() => setModal(false)}
      />
    </Container>
  );
};

export default Form;
