const useErrors = setError => {
  const newFormError = (key, message) =>
    setError(key, { message }, { shouldFocus: true });

  const errorHandler = response => {
    if (response.status === 400) {
      const { details } = response.data;

      if (details?.email) {
        newFormError('email', 'Email já utilizado!');

        return true;
      }

      if (details?.phone) {
        newFormError('phone', 'Telefone já utilizado!');

        return true;
      }

      if (details?.cpf) {
        newFormError('cpf', 'CPF já utilizado!');

        return true;
      }

      return false;
    }

    return false;
  };

  return errorHandler;
};

export default useErrors;
