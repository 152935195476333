/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => theme.styles.cardWidth}
  color: ${({ theme }) => theme.colors.dark};
  flex-direction: column;
  display: flex;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: white;
  max-width: 363px;

  .es_card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .es_card_image {
      border-radius: 99999px;
      overflow: hidden;
      width: 50px;
      height: 50px;

      img {
        object-fit: contain;
        width: 50px;
        height: 50px;
      }
    }

    h3 {
      font-size: 20px;
    }

    .es_title {
      flex: 1;
    }
  }

  .es_card_divider {
    height: 1px;
    width: 150px;
    background-color: #d2d2d2;
    margin: 10px auto 0px;
  }

  .es_card_body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px;

    > div {
      display: flex;
      gap: 10px;
    }

    .es_card_status {
      border-radius: 99999px;
      width: 15px;
      height: 15px;
      background-color: ${props =>
        props.status === 'running'
          ? '#00FD19'
          : props.status === 'finished'
          ? '#00FD19'
          : '#FD1E00'};
    }

    p {
      font-size: 15px;
    }
  }

  .es_card_footer {
    display: flex;
    align-items: center;
    gap: 8px;

    > svg {
      width: 15px;
      height: 15px;
      flex-shrink: 0;
    }

    p {
      font-size: 13px;
    }
  }

  .es_card_footer_2 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;

    button {
      border-radius: 999999px;
    }
  }
`;
