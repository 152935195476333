import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Loading,
  NButton,
  NInput,
  NPicture,
  NSelector,
  NTitle,
} from '~/components';
import { useAlerts, useApi, useAuth } from '~/hooks';
import { createDriver, verifyDriverCar } from '~/services/drivers';

import formErrors from '~/errors/formErrors';
import { getRegister } from '~/repositories/register';
import { getDomain } from '~/utils';
import { extraFields, fields, schema, userSchema } from './props';
import { Container, DivPic, Form } from './styles';

function dataURLtoFile(dataurl, filename) {
  const file = new File([new Blob([dataurl])], filename, {
    type: 'image/jpeg',
  });
  return file;
}

const FormCar = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { state } = useLocation();
  const save = getRegister();
  const [base64Images, setBase64Images] = useState([]);
  const { newErrorAlert } = useAlerts();
  const [isLoading, setIsLoading] = useState(true);

  const driverStatus = user.driverAndPassenger;

  useEffect(() => {
    if (driverStatus === 'CheckDriver')
      return navigate(`${getDomain()}/cadastro/veiculo/analise`);
    if (driverStatus === 'ActiveDriver')
      return navigate(`${getDomain()}/cadastro/veiculo/ativo`);
    return setIsLoading(false);
  }, []);

  const { request } = useApi();

  const { register, formState, control, handleSubmit, watch, setError } =
    useForm({
      resolver: yupResolver(state?.userId ? userSchema : schema),
      mode: 'all',
    });

  const [typeCar, setTypeCar] = useState();

  const cnh = watch('cnh');
  const crlv = watch('crlv');
  const photo = watch('picture');

  const typeCarOptions = [
    'Carro particular',
    'Táxi',
    'Mototaxi',
    'Entregador - carro',
    'Entregador - moto',
    'Carro Premium',
  ];

  function handleSelectTypeCar() {
    switch (typeCar) {
      case typeCarOptions[0]:
        return 'private_car';

      case typeCarOptions[1]:
        return 'taxi';

      case typeCarOptions[2]:
        return 'motorbike_taxi';

      case typeCarOptions[3]:
        return 'car_delivery_man';

      case typeCarOptions[4]:
        return 'motorbike_delivery_man';

      default:
        return 'premium_car';
    }
  }

  const submit = async data => {
    const obj = {};

    base64Images.forEach(base => {
      obj[base.name] = base.image;
    });

    request(
      () =>
        verifyDriverCar({ licensePlate: data.licensePlate.replace('-', '') }),
      () => {
        if (!typeCar) {
          return newErrorAlert('Preencha todos os campos');
        }
        let reqData;

        if (cnh && crlv && (state.picture || photo)) {
          if (state.userId) {
            reqData = {
              userId: state.userId,
              photoImage: dataURLtoFile(photo, 'photo'),
              cnhImage: cnh,
              licensePlate: data.licensePlate.replace('-', ''),
              carColor: data.carColor,
              carBrand: data.carBrand,
              carModel: data.carModel,
              carYear: data.carYear,
              crlvImage: crlv,
              typeCar: handleSelectTypeCar(),
              pix: data.pix,
            };

            const formData = new FormData();

            Object.entries(reqData).forEach(([key, value]) =>
              formData.append(key, value)
            );

            request(
              () => createDriver(formData),
              () => {
                navigate(`/${getDomain()}/`);

                return 'Aguarde a validação dos seus dados para iniciar suas viagens.\n Obrigada :)';
              },
              formErrors(setError)
            );
          } else {
            reqData = {
              name: state.name,
              cpf: state.cpf,
              pix: state.pix,
              password: state.password,
              photoImage: dataURLtoFile(state.picture, 'picture'),
              cnhImage: cnh,
              licensePlate: data.licensePlate.replace('-', ''),
              carColor: data.carColor,
              carBrand: data.carBrand,
              carModel: data.carModel,
              carYear: data.carYear,
              crlvImage: crlv,
              typeCar: handleSelectTypeCar(),
            };

            const { type } = state;

            navigate(`/${getDomain()}/cadastro/telefone`, {
              state: { type, reqData, phoneConfirmation: true },
            });
          }
        } else {
          newErrorAlert('Preencha todos os campos!');
        }

        return null;
      },
      () => setError('licensePlate', { message: 'Placa já utilizada!' })
    );
  };

  useEffect(() => {
    if (!cnh && !crlv) return;

    const images = [
      { image: cnh, name: 'cnh' },
      { image: crlv, name: 'crlv' },
    ];

    images.forEach(image => {
      if (!save?.driver?.[image.name] && image.image) {
        const fileRead = new FileReader();

        fileRead.onloadend = () => {
          const hasImage = base64Images.find(base => base.name === image.name);

          if (!hasImage) {
            setBase64Images(prevState => [
              ...prevState,
              { image: fileRead.result, name: image.name },
            ]);
          }
        };
        fileRead.readAsDataURL(image.image);
      }
    });
  }, [cnh, crlv]);

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <NTitle label="Cadastro do veículo" className="ntitle" />
      <Form onSubmit={handleSubmit(submit)} autoComplete="off">
        {state.userId && (
          <div className="div_pic">
            <p className="label">Insira sua foto aqui:</p>
            <Controller
              control={control}
              name="picture"
              render={({ field }) => <NPicture id="foto" {...field} />}
              rules={{ required: true }}
            />
          </div>
        )}

        {state.userId &&
          Object.entries(extraFields).map(([key, value]) => (
            <NInput
              key={key}
              error={formState.errors[key]?.message}
              {...value}
              {...register(key)}
            />
          ))}

        {Object.entries(fields).map(([key, value]) => (
          <NInput
            key={key}
            error={formState.errors[key]?.message}
            defaultValue={save?.driver?.[key]}
            {...value}
            {...register(key)}
          />
        ))}

        <NSelector
          placeholder="Selecione o tipo do seu veículo"
          label="Tipo do veículo"
          options={typeCarOptions}
          onChange={data => setTypeCar(data)}
          value={typeCar}
        />

        <DivPic>
          <p className="label">Insira a foto da sua cnh:</p>
          <Controller
            control={control}
            name="cnh"
            defaultValue={save?.driver?.cnh}
            render={({ field }) => (
              <NPicture
                id="cnh"
                imgDefault={save?.driver?.cnh}
                square
                {...field}
              />
            )}
            rules={{ required: true }}
          />

          <p className="label">Insira a foto da sua crlv:</p>
          <Controller
            control={control}
            name="crlv"
            defaultValue={save?.driver?.crlv}
            render={({ field }) => (
              <NPicture
                id="crlv"
                imgDefault={save?.driver?.crlv}
                square
                {...field}
              />
            )}
            rules={{ required: true }}
          />
        </DivPic>

        <NButton type="submit" label="Prosseguir" />
      </Form>
    </Container>
  );
};

export default FormCar;
