import styled from 'styled-components';

export const RequestCardContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 20px;

  padding: 1.25rem 0 0.75rem;
  margin-bottom: 1.25rem;
`;

export const RequestCardContent = styled.div`
  padding: 0 1rem;

  div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    text-align: center;

    svg {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  footer {
    margin: 0 auto;

    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    text-align: center;

    border-radius: 9999px;
    background: ${({ theme }) => theme.colors.dark};

    p {
      width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 0.875rem;
      color: #f6f6f6;
    }
  }
`;
