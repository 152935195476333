import React from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';

import { Button } from '../Button';

import {
  AddRequestForm,
  AddRequestModalCloseButton,
  AddRequestModalContainer,
  AddRequestModalContent,
  AddRequestModalOverlay,
  AddRequestModalTitle,
} from './styles';

export function AddRequestModal() {
  return (
    <AddRequestModalContainer>
      <AddRequestModalOverlay />

      <AddRequestModalContent>
        <header>
          <AddRequestModalCloseButton asChild>
            <HiOutlineArrowLeft />
          </AddRequestModalCloseButton>

          <AddRequestModalTitle> Pedidos </AddRequestModalTitle>

          <span />
        </header>

        <AddRequestForm>
          <input type="text" placeholder="Rua" />
          <input type="text" placeholder="Nome" />
          <input type="number" placeholder="Nº de referência" min={1} />
          <input type="text" placeholder="Contato" />
          <input type="number" placeholder="Valor" min={1} />
          <input type="text" placeholder="Troca" />

          <Button text="Confirmar" variant="primary" type="submit" />
        </AddRequestForm>
      </AddRequestModalContent>
    </AddRequestModalContainer>
  );
}
