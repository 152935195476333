import { yupResolver } from '@hookform/resolvers/yup';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';

import { NSmallButton, NTitle, NInput, NButton } from '~/components';
import { useApi } from '~/hooks';
import { passwordRecover, passwordRecoverToken } from '~/services/users';
import { getDomain } from '~/utils';

import { fields, schema } from './props';

import { Container } from './styles';

const RecoverPassword = () => {
  const code = useRef();

  const navigate = useNavigate();

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [validate, setValidate] = useState(false);

  const { request } = useApi();

  const { register, formState, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const sendToken = data => {
    const reqData = {
      password: data.password,
      cpf: data.cpf.replace(/\D/g, ''),
      strategy: 'sms',
    };

    request(
      () => passwordRecover(reqData),
      () => setValidate(true),
      () => {
        setError(true);
        setErrorText('CPF inválido!');
      }
    );
  };

  const onSubmit = () => {
    request(
      () => passwordRecoverToken(code.current.value),
      () => {
        navigate(`/${getDomain()}/`);

        return 'Senha alterada com sucesso!';
      },
      () => {
        setError(true);
        setErrorText('Token inválido!');
      }
    );
  };

  return (
    <Container error={error} validate={validate}>
      <NTitle label="Recupere sua senha" className="title" />
      <form onSubmit={handleSubmit(sendToken)} className="form">
        {Object.entries(fields).map(([key, value]) => (
          <NInput
            key={key}
            error={formState.errors[key]?.message}
            {...value}
            {...register(key)}
          />
        ))}

        <NSmallButton
          type="submit"
          label="Enviar"
          className="button_validate"
        />
      </form>

      <p className="error">{errorText}</p>

      {validate && (
        <div className="div_validation">
          <p>Digite o código enviado por SMS:</p>
          <VerificationInput
            classNames={{
              container: 'input-container',
              character: 'input',
              characterSelected: 'input-selected',
              characterInactive: 'input-inactive',
            }}
            ref={code}
            onChange={() => setError(false)}
          />

          <NButton className="button" label="Confirmar" onClick={onSubmit} />
        </div>
      )}
    </Container>
  );
};

export default RecoverPassword;
