/* eslint-disable react/react-in-jsx-scope */
import { useApp } from '~/hooks';

import { HiArrowLeft } from 'react-icons/hi';
import loading from '~/assets/icons/loading-buffering.gif';

import {
  BackButton,
  ButtonConfirm,
  Container,
  ContainerTitle,
  Header,
  TitleConfirm,
  Wrapper,
} from './styles';

export function SharedTripsPage({
  active,
  onClose = () => null,
  joinTrip,
  isLoading,
}) {
  const { app } = useApp();

  return (
    <Container active={active}>
      <Wrapper>
        <Header>
          <BackButton onClick={() => onClose()}>
            <HiArrowLeft size={24} color="#4D4D4D" />
          </BackButton>
        </Header>
        {!isLoading ? (
          <ContainerTitle>
            <TitleConfirm>Confirmar chamado?</TitleConfirm>
            <ButtonConfirm
              onClick={joinTrip}
              buttonConfirmColor={app.colors.primary}
            >
              Confirmar
            </ButtonConfirm>
          </ContainerTitle>
        ) : (
          <ContainerTitle>
            <TitleConfirm>Confirmando...</TitleConfirm>
            <img src={loading} alt="Imagem de carregamento" />
          </ContainerTitle>
        )}
      </Wrapper>
    </Container>
  );
}
export default SharedTripsPage;
