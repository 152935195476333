import styled from 'styled-components';

export const FooterWaiting = styled.footer`
  bottom: 0;
  width: 100%;
  margin-top: -56px;
  z-index: 1;
`;
export const FooterContainer = styled.div`
  padding: 44px 70px 55px 70px;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;
export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
`;
export const WaitingPassagers = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
export const Progress = styled.progress`
  width: 318px;
  height: 16px;
  border-radius: 10px;

  ::-webkit-progress-bar {
    background-color: #d9d9d9;
    border-radius: 10px;
  }
  ::-webkit-progress-value {
    background-color: ${props => props.progressColor};
    border-radius: 10px;
  }
`;
export const ValueTrip = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'Comfortaa';
  font-size: 20px;
  font-weight: 700;
`;
export const TitleWaitingPassagers = styled.span`
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'Comfortaa';
  font-size: 15px;
`;
export const ButtonSpeed = styled.button`
  background: ${props => props.buttonColorSpeed};
  width: 234px;
  padding: 16px 0;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.white};
`;
export const Waiting = styled.p`
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  margin: 0 24px;
  margin-top: 12px;
`;
export const LoadingIcon = styled.img`
  width: 30px;
  height: 30px;
`;
export const TimeOverlay = styled.div`
  position: relative;
  top: -43px;
  right: -125px;
  font-size: 25px;
  padding: 14px 0px;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 10px 10px 0px 0px;
  line-height: 27.88px;
  text-align: center;
  margin-right: 18px;
  width: 126px;
  height: 55px;
  margin-top: -56px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    font-size: 14px;
  }
`;
export const DriverInfosCar = styled.div`
  position: relative;
  top: -42px;
  left: -109px;
  font-size: 25px;
  padding: 14px 0px;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 10px 10px 0px 0px;
  line-height: 27.88px;
  margin-right: 18px;
  width: 126px;
  height: 55px;
  margin-top: -56px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  span {
    color: ${({ theme }) => theme.colors.white};
    font-size: 11px;
  }
`;
export const WaitingDriver = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const DriverInfos = styled.div`
  display: flex;
  gap: 15px;
  img {
    border-radius: 100%;
    width: 70px;
    height: 70px;
  }
`;
export const DriverName = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'Comfortaa';
  font-size: 20px;
  font-weight: 700;
  max-width: 16ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
export const DateRide = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-family: 'Comfortaa';
  font-size: 15px;
  font-weight: 700;
  margin-top: 10px;
`;
export const ContainerPoints = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.dark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  width: 65px;
  height: 62px;
  padding: 0px 17px;
  border-radius: 10px;
  p {
    color: #000;
    font-family: Comfortaa;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
  }
`;
export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 35px;
`;
export const ButtonMessage = styled.button`
  color: ${({ theme }) => theme.colors.dark};
  text-decoration: underline;
`;
export const ContainerRunning = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 17px;
`;
export const DriverPrice = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  text-align: center;
  font-family: 'Comfortaa';
  font-size: 16px;
  font-weight: 400;
`;
export const PixDriver = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
`;
export const PixName = styled.div`
  color: ${props => props.pixColor};
  text-align: center;
  font-family: 'Comfortaa';
  font-size: 16px;
  font-weight: 400;
`;
export const CopiedPix = styled.div`
  color: ${({ theme }) => theme.colors.dark};
  text-align: center;
  font-family: 'Comfortaa';
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0px;
  border-radius: 10px;
  background: #d9d9d9;
  width: 257px;
`;
