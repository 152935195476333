import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  background-color: white;
  padding: 33px 37px;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TitleModal = styled.h2`
  width: 246px;
  color: #4d4d4d;
  font-family: 'Comfortaa';
  font-size: 20px;
  font-weight: 400;
  text-align: center;
`;

export const PriceTrip = styled.h3`
  color: #4d4d4d;
  font-family: 'Comfortaa';
  font-size: 30px;
  font-weight: 400;
  margin: 25px 0;
`;

export const ButtonSpeed = styled.button`
  background: ${props => props.buttonColorSpeed};
  width: 234px;
  padding: 16px 0;
  border-radius: 10px;
  color: #ffffff;
`;

export const ButtonCloseModal = styled.button`
  background: #ffffff;
  width: 234px;
  padding: 16px 0;
  border-radius: 10px;
  color: #4d4d4d;
  margin-top: 12px;
`;
