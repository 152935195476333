import { useApp } from '~/hooks';

import { getStatus } from '~/repositories/status';

import headerIcon from '~/assets/icons/header-icon.svg';

import { Destiny, Header, HeaderTextRunning, HeaderTextWaiting } from './style';

export function NHeaderWaiting({ driver, tripLocations, actualTrip }) {
  const status = getStatus();
  const { app } = useApp();

  return (
    <Header headerColor={app.colors.primary}>
      {driver && <img src={headerIcon} alt="header" />}

      {driver && status === 'waiting' && (
        <HeaderTextWaiting>{tripLocations.boar}</HeaderTextWaiting>
      )}

      {actualTrip.status === 'waiting_passengers' && (
        <HeaderTextWaiting>Aguardando passageiros</HeaderTextWaiting>
      )}

      {actualTrip.status === 'waiting_driver' && (
        <Destiny>Aguardando motorista...</Destiny>
      )}

      {status === 'running' && (
        <HeaderTextRunning>{tripLocations.land}</HeaderTextRunning>
      )}
    </Header>
  );
}
