import { object, string } from 'yup';

const schema = object({
  phone: string()
    .matches(/\(\d\d\) \d{5}-\d{4}/, 'Adicione um número válido!')
    .required('Preencha esse campo!'),
  password: string().required('Preencha esse campo!'),
});

export default schema;
