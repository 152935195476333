import { useState, useEffect } from 'react';

const usePersistedState = (key, initalValue) => {
  const [state, setState] = useState(() => {
    const storage = JSON.parse(localStorage.getItem(key));
    if (storage) {
      return storage;
    }
    return initalValue;
  });
  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);
  return [state, setState];
};

export default usePersistedState;
