import styled from 'styled-components';

export const Container = styled.div`
  padding: 19px 0 24px;
  position: relative;

  ${({ theme }) => theme.styles.cardWidth}
  border-radius: 20px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  overflow: hidden;

  > .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: white;
    align-items: center;
    padding: 20px 15px;
    min-height: 80px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    .title {
      flex: 1;
      margin-top: -24px;
      text-align: center;
    }

    .title_text,
    .title_text_profit {
      color: black;

      font-size: 1.1rem !important;
    }

    .title_text_profit {
      color: green;

      margin-top: 10px;
    }

    .icon {
      margin-left: 15px;
    }
  }

  > .label {
    margin-top: -24px;
    font-size: 18px;

    color: black;
  }

  .route-column {
    width: 80%;

    > p {
      font-size: 14px;

      & + p {
        transform: translateY(16px);
      }
    }
  }
`;

export const Button = styled.button`
  margin-right: 1.5rem;

  width: 20px;
  height: 20px;
  border-radius: 100px;
  position: absolute;
  right: 0;

  margin-top: -19px;

  .horizontal,
  .vertical {
    position: absolute;

    border-radius: 10000px;
    background: #000000;
  }

  .horizontal {
    top: 4px;
    left: 4px;

    width: 17px;
    height: 3px;
    border-radius: 2000px;
  }

  .vertical {
    top: ${({ open }) => (open ? '9px' : '-3px')};
    left: 11px;

    width: 3px;
    height: ${({ open }) => (open ? 0 : '17px')};
  }
`;

export const PassengersList = styled.div`
  padding: 0 24px;
  width: 100%;
  max-width: 500px;
  height: ${({ open, qt }) => (open ? `${96 * qt + 56}px` : 0)};
  transform: translateY(32px);

  > .div_key {
    strong {
      font-size: 16px;
    }
  }

  .route {
    display: flex;
    width: 100%;
    margin-top: 24px;
  }

  .rate-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    right: 24px;
    align-self: center;
  }
`;
