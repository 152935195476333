import React from 'react';

import { NButton } from '~/components';

import { setStatus } from '~/repositories/status';
import { ReactComponent as FlagLeft } from '~/assets/icons/flag_left.svg';
import { ReactComponent as FLagRight } from '~/assets/icons/flag_right.svg';

import { Container } from './styles';

const Success = () => {
  const finish = () => {
    setStatus('home');
    window.location.reload();
  };

  return (
    <Container>
      <FlagLeft className="flag_left" />
      <FLagRight className="flag_right" />
      <p className="title">Parabéns.</p>
      <p>Sua viagem foi finalizada com sucesso. :)</p>
      <p>Bom trabalho motorista!</p>
      <NButton
        label="Vamos finalizar mais!"
        className="btn"
        onClick={() => finish()}
      />
    </Container>
  );
};

export default Success;
