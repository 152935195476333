import NButton from '../NButton';

import { Container } from './styles';

const CustomModal = ({
  error,
  title,
  label,
  buttonLabel = '',
  onClose = () => null,
  action = () => null,
  ...rest
}) => (
  <Container onClose={onClose} {...rest}>
    <p className="title">{title}</p>
    <p className="label">{label}</p>
    <NButton className="confirm-button" label={buttonLabel} onClick={action} />
  </Container>
);

export default CustomModal;
