import React from 'react';

import { Container } from './styles';

const NButton = ({ label = '', color, disabled, type = 'button', ...rest }) => (
  <Container disabled={disabled} type={type} buttonColor={color} {...rest}>
    {label}
  </Container>
);

export default NButton;
