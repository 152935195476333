import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 14;
  width: 100%;
  background-color: rgba(76, 76, 76, 1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Wrapper = styled.div`
  width: 90%;
  max-width: 550px;
  padding: 32px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;

export const Notification = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 10px;
  span {
    font-size: 22px;
  }
`;

export const NotifyIcon = styled.div`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 35px;
  }
`;

export const Seats = styled.div`
  font-size: 26px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 2px solid white;
`;

export const Details = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .divisor {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  margin-top: 20px;
`;

export const Price = styled.div`
  font-size: 35px;
  margin-bottom: 25px;
`;

export const Others = styled.div`
  font-size: 20px;
  font-weight: 400;
  margin-top: 5px;
`;

// export const Route = styled.div`
//   width: 100%;
//   max-width: 400px;
//   display: flex;
//   align-items: flex-start;
//   justify-content: center;
//   flex-direction: column;
//   padding-top: 20px;
//   padding-bottom: 20px;
// `;

export const RouteList = styled.div`
  padding-left: 15px;
`;

export const RouteItem = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 15px;
`;

export const RouteItem2 = styled.div`
  min-height: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 15px;
`;

export const PointedDivisor = styled.img`
  height: 60px;
  margin-top: 0 !important;
`;

export const RouteDetail = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
`;

export const RouteAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
`;

// export const RouteWrapper = styled.div`
//   height: 55vh;
//   overflow: hidden;
//   overflow-y: auto;

//   &::-webkit-scrollbar {
//     width: 8px;
//     height: 50px;
//     background: #cecece50;
//     border-radius: 999px;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: #fff;
//     border-radius: 999px;
//   }
// `;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Route = styled.div`
  width: 100%;
  margin-top: 34px;
`;

export const RouteHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 22px;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 10px;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const ProfileName = styled.h1`
  color: white;
  font-size: 16px;
  font-weight: 400;
`;

export const ProfileSince = styled.p`
  color: white;
  font-size: 10px;
  font-weight: 400;
`;

export const HeaderRight = styled.div`
  display: flex;
  gap: 30px;
`;

export const HeaderRightItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  height: 100%;
`;

export const HeaderItemText = styled.p`
  color: white;
  font-size: 14px;
`;

export const RouteInfoValues = styled.h1`
  color: white;
  font-size: 18px;
  font-weight: 400;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  justify-self: end;
  align-items: center;
  width: 100%;
`;

export const ActionsRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-top: 32px;
`;

export const Accept = styled.button`
  background-color: #22f100;
  width: 45%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
`;

export const Reject = styled.button`
  background-color: #ff0000;
  width: 45%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
`;
