import styled from 'styled-components';

export const Header = styled.header`
  background: ${props => props.headerColor};
  align-items: center;
  padding: 20px 15px;
  min-height: 80px;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const HeaderTextWaiting = styled.p`
  margin: 0;
  margin-left: 12px;
  margin-top: 3px;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
`;

export const HeaderTextRunning = styled.p`
  margin: 0;
  margin-left: 12px;
  margin-top: 3px;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
`;

export const Destiny = styled.p`
  margin: 0;
  margin-left: 12px;
  font-size: 16px;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
`;
