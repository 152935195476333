import React from 'react';
import { MdErrorOutline, MdOutlineCheckCircle } from 'react-icons/md';

import NButton from '../NButton';

import { Container } from './styles';

const NAlert = ({
  error,
  title = error ? 'Erro' : 'Sucesso',
  label,
  confirmText = 'OK',
  onClose = () => null,
  ...rest
}) => (
  <Container {...rest} onClose={onClose}>
    {error ? (
      <MdErrorOutline className="icon" size={70} />
    ) : (
      <MdOutlineCheckCircle className="icon" size={70} />
    )}
    <p className="title">{title}</p>
    <p className="label">{label}</p>
    <NButton className="confirm-button" label={confirmText} onClick={onClose} />
  </Container>
);

export default NAlert;
