import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${({ active }) => (active ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 1;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  background-color: white;
  width: 340px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 15px;
  gap: 15px;

  .label {
    font-size: 20px;
    margin-top: 30px;
  }

  .back-button {
    margin-bottom: 30px;
  }
`;
