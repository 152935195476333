import storage from './storage';

export const setAppId = appId => storage.setItem('appId', appId);
export const getAppId = () => storage.getItem('appId');
export const removeAppId = () => storage.removeItem('appId');

export const setAppColors = colors =>
  storage.setItem('appColors', JSON.stringify(colors));
export const getAppColors = () => JSON.parse(storage.getItem('appColors'));

export const setAppName = name => storage.setItem('name', name);
export const getAppName = () => storage.getItem('name');

export const setAppDomain = domain => storage.setItem('domain', domain);
export const getAppDomain = () => storage.getItem('domain');

export const setApp = ({ id, colors, name, domain }) => {
  setAppId(id);
  setAppColors(colors);
  setAppName(name);
  setAppDomain(domain);
};

export const getApp = () => ({
  id: getAppId(),
  colors: getAppColors(),
  name: getAppName(),
  domain: getAppDomain(),
});

export const setFirst = value => storage.setItem('first', value);
export const getFirst = () => storage.getItem('first');
