import React from 'react';
import { IoMdAdd } from 'react-icons/io';

import { ButtonContainer } from './styles';

export function Button({ text, variant }) {
  return (
    <ButtonContainer variant={variant}>
      {variant === 'secondary' && <IoMdAdd size={20} />}
      {text}
    </ButtonContainer>
  );
}
