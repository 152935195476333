import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Background,
  NButton,
  NCardPeople,
  NModalSure,
  NTitle,
} from '~/components';
import { useAlerts, useApi, useApiEffect, useSocketContext } from '~/hooks';
import useGeolocation from '~/hooks/useGeolocation';
import { setStatus } from '~/repositories/status';
import { getTripId } from '~/repositories/trip';
import { getPassengerById } from '~/services/passengers';
import { getTripById } from '~/services/trips';
import { getDomain } from '~/utils';

import { getCorrectTime } from '~/utils/correctTime';

import { Container } from './styles';

const Embarkations = () => {
  const navigate = useNavigate();

  const { newErrorAlert } = useAlerts();

  const [trip, setTrip] = useState();
  const [passengers, setPassengers] = useState([]);
  const [modal, setModal] = useState(false);

  const { socket } = useSocketContext();

  const { request } = useApi();

  const tripId = getTripId();

  const onCancel = () => {
    const pass = trip.passengers.filter(
      e => e.status !== 'waiting' && e.status !== 'canceled'
    );
    if (pass.length > 0) {
      newErrorAlert('Você não pode cancelar uma viagem enquanto ela ocorre.');
      setModal(false);
    } else {
      socket.emit('driver-cancel');
      setStatus('home');
      window.location.reload();
    }
  };

  const location = useGeolocation();

  useEffect(() => {
    if (!location.latitude || !location.longitude) return;

    socket.emit('update-location', {
      latitude: Number(location.latitude),
      longitude: Number(location.longitude),
    });
  }, [location]);

  const attData = data => {
    setTrip(data);
    const pass = data.passengers.filter(e => e.status === 'waiting');
    setPassengers(pass);

    const runningPass = data.passengers.filter(e => e.status === 'running');

    if (pass.length < 1 && runningPass.length < 1) {
      newErrorAlert('Todos os passageiros dessa viagem cancelaram');
      socket.emit('finish');
      setStatus('home');
      window.location.reload();
    }
  };

  useApiEffect(
    () => getTripById(tripId),
    response => attData(response.data)
  );

  useEffect(() => {
    socket.on('passenger-boarding', () => {
      request(
        () => getTripById(tripId),
        response => attData(response.data)
      );
    });
    socket.on('passenger-cancel', () => {
      request(
        () => getTripById(tripId),
        response => attData(response.data)
      );
    });

    socket.on('finish', () => {
      setStatus('success');
      window.location.reload();
    });
    socket.on('no-trip', () => {
      setStatus('home');
      window.location.reload();
    });
  }, []);

  const passengerBoarding = id => {
    socket.emit('passenger-boarding', { id });
  };
  const passengerCancel = id => {
    socket.emit('passenger-cancel', { id });
  };
  const message = id => {
    // socket.emit('driver-arrived', { id });
    // newSuccessAlert('Você avisou ao passageiro que chegou :)');

    request(
      () => getPassengerById(id),
      response => {
        if (response.data.phone)
          window.open(`https://wa.me/${response.data.phone}`);
        else
          newErrorAlert('Ops! tivemos um problema com o número do passageiro');
      }
    );
  };

  return (
    <Container>
      <Background />
      <div className="wrapper">
        <NTitle
          label="Embarque"
          color="white"
          noBack
          onForward={() => navigate(`/${getDomain()}/desembarques`)}
        />
        {trip && (
          <p className="info">
            {trip.cost.total} - {getCorrectTime(trip.time)}
          </p>
        )}
        {passengers && (
          <div className="embarkations-div">
            {passengers.map((item, i) => (
              <NCardPeople
                name={item.boardingAddress.name}
                address={item.boardingAddress.reference}
                people={item.passenger}
                status={item.status}
                cost={item.cost}
                onConfirm={() => message(item.passenger.id)}
                onMensagem={() => passengerBoarding(item.passenger.id)}
                onCancel={() => passengerCancel(item.passenger.id)}
                key={i}
              />
            ))}
          </div>
        )}
        {passengers.length < 1 && (
          <p className="text_warning">
            Todos os passageiros dessa viagem já foram pegos. Ótimo trabalho!
          </p>
        )}
      </div>
      <NButton
        label="Desembarque"
        onClick={() => navigate(`/${getDomain()}/desembarques`)}
      />
      <NModalSure
        visible={modal}
        label="Tem certeza que quer desistir dessa viagem?"
        onCancel={() => setModal(false)}
        onConfirm={() => onCancel()}
        onClose={() => setModal(false)}
      />
      <NButton
        color="red"
        label="CANCELAR"
        className="cancel"
        onClick={() => setModal(true)}
      />
    </Container>
  );
};

export default Embarkations;
