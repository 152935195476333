/* eslint-disable prettier/prettier */

import React from 'react';

import { copyText } from '~/utils/copyText';
import { ReactComponent as PencilIcon } from '~/assets/icons/pencil.svg';
import timelineIcon from '~/assets/icons/timeline.svg';

import badIcon from '~/assets/icons/bad_face.png';
import happyIcon from '~/assets/icons/happy_face.png';
import neutralIcon from '~/assets/icons/neutral_face.png';
import { TiArrowBackOutline } from 'react-icons/ti';
import { BiDotsVerticalRounded } from 'react-icons/bi';

import { Container } from './styles';
import NCardItem from '../NCardItem';
import Divisor from '../../assets/images/divisor.svg';

const NCardHistory = ({
  date,
  id,
  type,
  time,
  price,
  originNeighbour,
  destinyNeighbour,
  origin,
  destiny,
  driver,
  driverPhoto,
  rate,
  changeBoarding,
  changeLanding,
  goToTrip = () => null,
  pix,
  ...rest
}) => {

  const rateIcon = () => {
    switch (rate) {
      case 1:
        return badIcon;
      case 3:
        return happyIcon;
      default:
        return neutralIcon;
    }
  };
  return (
    <Container {...rest}>
      <header>
        <img src={driverPhoto} alt="driverpicture" />
        <div>
          <p className="date-price">
            {date} - {time} - {price}
          </p>
          <p className="driver">{driver}</p>
        </div>
        <img src={rateIcon()} alt="rate-icon" className="rate-icon" />
      </header>

      <div className="divisorWrapper">
        <img src={Divisor} alt="Divisor" className="divisor" />
      </div>

      <p className="places">
        {originNeighbour} - {destinyNeighbour}
      </p>

      <div className="flex-row justify-between">
        <div className="flex-row">
          <img src={timelineIcon} alt="timeline-icon" />
          <div className="flex-col">
            {(origin.length <= 50) && (
              <p className="origin-street">
                {origin} <PencilIcon className="pencil-icon" size={10} onClick={changeBoarding} />
              </p>
            )}
            {origin.length > 50 && (
              <p className="origin-street">
                {origin.slice(0, 50)}...
                <PencilIcon className="pencil-icon" size={10} onClick={changeBoarding} />
              </p>
            )}
            {(destiny.length <= 50) && (
              <p className="destiny-street mt">
                {destiny}
                <PencilIcon className="pencil-icon" size={10} onClick={changeLanding} />
              </p>
            )}
            {destiny.length > 50 && (
              <p className="destiny-street mt">
                {destiny.slice(0, 50)}...
                <PencilIcon className="pencil-icon" size={10} onClick={changeLanding} />
              </p>
            )}
          </div>
        </div>

        <div className="rightContent">
          <TiArrowBackOutline size={30} color="#4D4D4D" />
          <BiDotsVerticalRounded size={30} color="#4D4D4D" />
        </div>
      </div>

      <div className="sourceWrapper">
        <div className="sourceButtons">
          <button className="invert" type="button">
            <span>Inverter</span>
          </button>
          <button
            className="copy"
            type="button"
            onClick={() => {
              const url = window.location.href;
              copyText(
                `${url}${url[url.length - 1] === '/' ? '' : '/'}desembarque/${id}`
              );
            }}>
            <span>Copiar</span>
          </button>
        </div>
      </div>

      <div className="item-div">
        {/* <NCardItem small label={driver} /> */}
        <NCardItem onClick={goToTrip} small primary label="Chamar novamente" />
      </div>
    </Container>
  );
};

export default NCardHistory;
