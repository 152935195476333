import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '~/assets/icons/logo.svg';

import { NButton } from '~/components';
import { useApp } from '~/hooks';

import NModalInstallPwa from '~/components/NModalInstallPwa';
import NModalPwaLogin from '~/components/NModalPwaLogin';

import { getDomain } from '~/utils';
import Form from './Form';
import { Container } from './styles';

const Login = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState();
  const { app } = useApp();

  return (
    <Container>
      <img src={app.logo || logo} alt="logo" className="logo" />
      {/* <p className="title">{app.name || 'Weptek'}</p> */}
      <Form recover={`/${getDomain()}/password_recover`} />
      <p className="question">Ainda não tem uma conta?</p>
      <NButton
        type="button"
        label="Cadastre-se"
        color="white"
        onClick={() => navigate(`/${getDomain()}/cadastro`)}
      />
      <br />
      <NModalPwaLogin />
      {openModal && (
        <NModalInstallPwa style={{ margin: 0 }} setOpenModal={setOpenModal} />
      )}
    </Container>
  );
};

export default Login;
