/* eslint-disable prettier/prettier */
/* eslint-disable no-alert */
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Background,
  NTitle,
  NSearch,
  NModalSure,
  TripPopup,
} from '~/components';
import NCardTripDriver from '~/components/NCardTripDriver';
import { useApi, useApiEffect, useSocketContext } from '~/hooks';
import { getTripsHistory } from '~/services/drivers';
import { getTripsDriver } from '~/services/trips';

import { Container, CardTripsWrapper } from './styles';

const Trips = () => {
  const [trips, setTrips] = useState([]);
  const [tripId, setTripId] = useState();
  const [anyTrips, setAnyTrips] = useState(false);


  const [driverLat, setDriverLat] = useState('');
  const [driverLng, setDriverLng] = useState('');

  const [pages, setPages] = useState([]);
  const [page, setPage] = useState(1);

  const [search, setSearch] = useState('');
  const [modal, setModal] = useState(false);

  const [popupData, setPopupData] = useState({});
  const [tripPopupVisible, setTripPopupVisible] = useState(false);

  const { request } = useApi();

  const { socket } = useSocketContext();

  const [searchParams] = useSearchParams();

  const attData = data => {
    setTrips(data.content);
    setPages(data.pages);
  };

  const changePage = pageNumber => {
    setPage(pageNumber);
    request(
      () => getTripsHistory(pageNumber),
      response => attData(response.data)
    );
  };

  useApiEffect(getTripsDriver, response => {
    if (response.data.length > 0) setAnyTrips(false);
    else setAnyTrips(true);
    setTrips(response.data);
  });

  function showLoc(position) {
    setDriverLat(position.coords.latitude)
    setDriverLng(position.coords.longitude);
  }

  function getLoc() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showLoc);
    } else {
      alert("Seu navegador não suporta esse recurso");
    }
  }

  useEffect(() => {
    getLoc();
    socket.on('update-available-trips', () =>
      request(getTripsDriver, response => {
        if (response.data.length > 0) setAnyTrips(false);
        else setAnyTrips(true);
        setTrips(response.data);
      })
    );

    socket.on('receive-trip', data => {
      setPopupData(data);
      setTripPopupVisible(true);

      setTimeout(() => {
        setTripPopupVisible(false);
      }, 30 * 1000);
    });
  }, []);

  function acceptTrip(id) {
    setModal(false);
    socket.emit('accept-trip', { id });
  }

  function refuseTrip(id) {
    setTripPopupVisible(false);
    socket.emit('refuse-trip', { id });
  }

  function redirect(value) {
    setModal(true);
    setTripId(value.id);
  }

  const tripParamId = searchParams.get('trip');

  const cardsTrips = trips.map(trip => (
    <NCardTripDriver
      key={trip.id}
      adress1={trip.route.boardingDistrict}
      adress2={trip.route.landingDistrict}
      isDriver
      seats={trip.seats}
      price={trip.cost.total}
      passengers={trip.passengers}
      trip={trip}
      driverLat={driverLat}
      driverLng={driverLng}
      className="card"
      onClick={() => redirect(trip)}
    />
  ));

  const cardParamTrip = trips.map(
    trip =>
      trip.id === tripParamId && (
        <NCardTripDriver
          key={trip.id}
          adress1={trip.route.boardingDistrict}
          adress2={trip.route.landingDistrict}
          isDriver
          cost={trip.cost}
          seats={trip.seats}
          price={trip.cost.total}
          trip={trip}
          driverLat={driverLat}
          driverLng={driverLng}
          passengers={trip.passengers}
          className="card"
          onClick={() => redirect(trip)}
        />
      )
  );

  return (
    <Container>
      <Background />
      <div className="div_top">
        <NTitle label="Viagens" className="title_viagens" color="white" />
      </div>

      <NSearch
        className="nsearch"
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder="Pesquise pelo local de embarque.."
      />

      {anyTrips && (
        <p className="title_warning">Nenhuma viagem disponível no momento</p>
      )}

      <CardTripsWrapper>
        {tripParamId ? cardParamTrip : cardsTrips}
      </CardTripsWrapper>

      {pages && pages.total > 1 && (
        <div className="div_next">
          {pages.current > 1 && (
            <button
              onClick={() => changePage(page - 1)}
              type="button"
              className="advanced"
            >
              {`<`}
            </button>
          )}
          {pages.current < pages.total && (
            <button
              onClick={() => changePage(page + 1)}
              type="button"
              className="advanced"
            >
              &gt;
            </button>
          )}
        </div>
      )}

      <NModalSure
        visible={modal}
        label="Tem certeza que quer entrar nesta viagem?"
        onCancel={() => setModal(false)}
        onConfirm={() => acceptTrip(tripId)}
        onClose={() => setModal(false)}
      />

      {tripPopupVisible && (
        <TripPopup
          trip={popupData}
          driverLat={driverLat}
          driverLng={driverLng}
          onAccept={() => acceptTrip(popupData.id)}
          onRefuse={() => refuseTrip(popupData.id)}
        />
      )}
    </Container>
  );
};

export default Trips;
