import * as Dialog from '@radix-ui/react-dialog';
import styled from 'styled-components';

export const AddRequestModalContainer = styled(Dialog.Portal)``;

export const AddRequestModalOverlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
`;

export const AddRequestModalContent = styled(Dialog.Content)`
  width: 90vw;
  padding: 1.5rem 2rem;
  border-radius: 12px;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: ${({ theme }) => theme.colors.white};

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
export const AddRequestModalCloseButton = styled(Dialog.Close)`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
`;

export const AddRequestModalTitle = styled(Dialog.Title)`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const AddRequestForm = styled.form`
  margin-top: 2rem;

  input {
    width: 100%;
    padding: 0 0.75rem;
    height: 3.25rem;
    margin-bottom: 1rem;

    border: 0;
    border-radius: 10px;
    font-size: 0.875rem;
    background: ${({ theme }) => theme.colors.dark};
    color: ${({ theme }) => theme.colors.white};

    &::placeholder {
      color: ${({ theme }) => theme.colors.white};
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`;
