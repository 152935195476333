import { object, string } from 'yup';

export const fields = {
  cpf: {
    mask: '999.999.999-99',
    label: 'CPF:',
    placeholder: 'Insira o seu CPF..',
  },
  password: {
    type: 'password',
    label: 'Nova senha:',
    placeholder: 'Insira a nova senha da sua conta..',
  },
};

export const schema = object({
  cpf: string()
    .matches(/\d{3}.\d{3}.\d{3}-\d{2}/, 'Adicione um CPF válido!')
    .required('Preencha esse campo!'),
  password: string()
    .required('Preencha esse campo!')
    .min(8, 'Senha muito curta!'),
}).required();
