import { useEffect, useRef, useState } from 'react';

const useCountdown = initialTime => {
  const [timer, setTimer] = useState(initialTime);
  const [isCounting, setIsCounting] = useState(false);
  const id = useRef(null);

  const clear = () => {
    window.clearInterval(id.current);
  };

  const toggleCounter = () => {
    setIsCounting(prev => !prev);
  };

  useEffect(() => {
    if (!isCounting) return;
    id.current = window.setInterval(() => {
      setTimer(time => time - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clear();
  }, [isCounting]);

  useEffect(() => {
    if (timer === 0) {
      clear();
      setIsCounting(false);
      setTimer(initialTime);
    }
  }, [timer]);

  return { timer, toggleCounter, isCounting, setIsCounting, setTimer };
};

export default useCountdown;
