import styled from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => theme.styles.cardWidth}
  color: ${({ theme }) => theme.colors.dark};
  flex-direction: column;
  display: flex;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: white;
`;

export const CardHeader = styled.header`
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
`;

export const TripTotalValue = styled.h1`
  color: #000;
  font-size: 1.75rem;
`;

export const TripValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TripValues = styled.h2`
  color: #000;
  font-size: 1rem;
`;

export const Seats = styled.div`
  font-size: 1.5rem;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  border: 1px solid #000;
`;

export const Route = styled.div`
  width: 100%;
  margin-top: 2.3rem;

  .divisorWrapper {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .divisor {
    filter: brightness(0) saturate(100%);
  }
`;

export const RouteHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.3rem;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 100px;
  margin-right: 10px;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const ProfileName = styled.h1`
  color: #000;
  font-size: 16px;
  font-weight: 400;
`;

export const ProfileSince = styled.p`
  color: #000;
  font-size: 10px;
  font-weight: 400;
`;

export const HeaderRight = styled.div`
  display: flex;
  gap: 10px;
`;

export const HeaderRightItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  height: 100%;
`;

export const HeaderItemText = styled.p`
  color: #000;
  font-size: 14px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RouteItem2 = styled.div`
  min-height: 35px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  font-size: 15px;
`;

export const RouteItem = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 15px;
`;

export const RouteList = styled.div`
  padding-left: 15px;
`;

export const PointedDivisor = styled.img`
  height: 60px;
  margin-top: 0 !important;
  filter: brightness(0) saturate(70%);
`;

export const RouteAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 8px;
`;

export const RouteInfoValues = styled.h1`
  color: black;
  font-size: 18px;
  font-weight: 400;
`;
