import styled from 'styled-components';
import { MapContainer } from 'react-leaflet';

export const Container = styled(MapContainer)`
  z-index: -1;
  width: 100vw;
  height: 74vh;
  outline: none;
  .leaflet-marker-icon,
  .leaflet-marker-shadow {
    display: none !important;
  }
  .icon {
    display: block !important;
  }
  .leaflet-routing-container {
    display: none;
  }
`;
