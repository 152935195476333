import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.light_gray};

  display: flex;
  flex-direction: column;
  align-items: center;

  .div_top {
    display: flex;
    flex-direction: row;
    z-index: 10;
    justify-content: center;
    align-items: center;

    p {
      color: white;
    }

    h1 {
      color: white;
      font-size: 20px;
    }
    width: 100vw;

    margin-top: 40px;
    margin-bottom: 20px;
    > h1 {
      margin-top: 15px;
      margin-bottom: 20px;
    }
  }

  .card {
    z-index: 10;
    margin-bottom: 20px;
  }

  > .img {
    position: absolute;
    bottom: 50px;
    z-index: 0;
  }

  > .text_warning {
    z-index: 0;
    text-align: center;
    color: white;
  }

  .div_next {
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 20px;

    gap: 15px;

    .advanced {
      font-size: 30px;
    }
  }
`;

export const UserStatus = styled(Link)`
  color: ${props => (props.status === 'ActiveDriver' ? '#4D4D4D' : '#fff')};
  font-weight: 400;
  font-size: 0.9375rem;
  cursor: pointer;
  height: 3.125rem;
  z-index: 5;
  background-color: ${props =>
    props.status === 'ActiveDriver' ? '#00FD19' : '#FD1E00'};
  border-radius: 0.625rem;
  padding: 1.5rem 1rem;
  width: calc(100vw - 48px);
  max-width: 544px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.3rem;
  text-decoration: none;
`;
