import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getDomain } from '~/utils';
import {
  Button,
  CloseBnt,
  CloseText,
  ContainerModal,
  DivIcon,
  ModalContainer,
  Title1,
} from './styles';
import ShareIcon from '../../assets/icons/ShareIcon.svg';

export default function NModalInstallPwa({ setOpenModal }) {
  const navigate = useNavigate();
  return (
    <ContainerModal>
      <ModalContainer>
        <Title1>Instale o app sem consumir memória.</Title1>
        <DivIcon src={ShareIcon} alt="Instalar" />
        <Button
          type="button"
          onClick={() => navigate(`/${getDomain()}/instalacao`)}
        >
          Instalar
        </Button>
        <CloseBnt
          type="button"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          <CloseText>Não, obrigado</CloseText>
        </CloseBnt>
      </ModalContainer>
    </ContainerModal>
  );
}
