import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 10px;

    > .button_validate {
      margin-top: 20px;
    }
  }

  .div_validation {
    display: flex;
    flex-direction: column;
    align-items: center;

    opacity: ${({ validate }) => (validate ? '1' : '0')};

    margin-top: 20px;

    > .value {
      margin: 10px 0;

      font-weight: 700;
    }

    .input-container {
      margin-top: 32px;

      ${({ theme }) => theme.styles.itemWidth}

      display: flex;
      justify-content: space-between;

      .input {
        background: ${({ theme }) => theme.colors.dark};
        border-radius: 16px;
        ${({ error, theme }) =>
          error && `border: 1px solid ${theme.colors.red};`}
        border: 1px solid
        ${({ theme, error }) => (error ? '#FA6C6C' : theme.colors.dark)};

        color: ${({ error }) => (error ? '#FA6C6C' : 'white')};
        font: 400 30px Comfortaa, sans-serif;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .input-selected {
        border-color: ${({ theme }) => theme.colors.primary};
      }
    }

    > .button {
      margin-top: 20px;
    }
  }

  > .error {
    margin: 20px 0 0 16px;

    opacity: ${({ error }) => (error ? 1 : 0)};

    color: ${({ theme }) => theme.colors.red};
    font-size: 15px;

    overflow: hidden;
  }

  > .title {
    margin: 51px 0 20px;
  }
`;
