import L from 'leaflet';
import 'leaflet-routing-machine';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

function RoutingLeafEl({ driverLocation, passengerAddress }) {
  const map = useMap();

  const car =
    driverLocation &&
    L.latLng(driverLocation.latitude, driverLocation.longitude);

  const boarding = L.latLng(
    passengerAddress.boardingCoordinates.lat,
    passengerAddress.boardingCoordinates.lng
  );
  const landing = L.latLng(
    passengerAddress.landingCoordinates.lat,
    passengerAddress.landingCoordinates.lng
  );

  const centerPassengerLat =
    (passengerAddress.boardingCoordinates.lat +
      passengerAddress.landingCoordinates.lat) /
    2;

  const centerPassengerLng =
    (passengerAddress.boardingCoordinates.lng +
      passengerAddress.landingCoordinates.lng) /
    2;
  useEffect(() => {
    if (!map) return;

    map.setView(
      !driverLocation ? L.latLng(centerPassengerLat, centerPassengerLng) : car,
      !driverLocation ? 12 : 17
    );

    const routingControl = L.Routing.control({
      waypoints: !driverLocation ? [boarding, landing] : [car, boarding],
      lineOptions: {
        styles: [
          {
            color: 'blue',
            opacity: 0.6,
            weight: 4,
          },
        ],
      },
      addWaypoints: false,
      draggableWaypoints: false,
      fitSelectedRoutes: false,
      showAlternatives: false,
    }).addTo(map);

    // eslint-disable-next-line consistent-return
    return () => map.removeControl(routingControl);
  }, [map, driverLocation]);

  return null;
}

export default RoutingLeafEl;
