import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { getDomain } from '~/utils';
import {
  Container,
  Buttonback,
  HeadTitleContainer,
  HeadTitle,
  Title,
  SkipButton,
  DoneButton,
} from './styles';
import ArrowBack from '../../../assets/icons/ArrowBack.svg';

export default function ApplePage() {
  const navigate = useNavigate();

  return (
    <Container>
      <Buttonback
        type="button"
        onClick={() => navigate(`/${getDomain()}/instalacao`)}
      >
        <img src={ArrowBack} alt="Voltar" />
      </Buttonback>

      <HeadTitleContainer>
        <HeadTitle>Instalação</HeadTitle>
      </HeadTitleContainer>

      <Title>
        1 - Clique nos três pontinhos localizado no canto superior direito da
        tela! <br />
        <br />
        <br />2 - Clique em “ ADICIONAR A TELA INICIAL”
      </Title>

      <DoneButton onClick={() => navigate(`/${getDomain()}/cadastro`)}>
        Já fiz isso
      </DoneButton>

      <SkipButton onClick={() => navigate(`/${getDomain()}/cadastro`)}>
        Pular
      </SkipButton>
    </Container>
  );
}
