import { useEffect, useState } from 'react';

import { Background, NCardPeople, NTitle } from '~/components';
import { useAlerts, useApi, useApiEffect, useSocketContext } from '~/hooks';
import { setStatus } from '~/repositories/status';
import { getTripId } from '~/repositories/trip';
import { getPassengerById } from '~/services/passengers';
import { getTripById } from '~/services/trips';

import { getCorrectTime } from '~/utils/correctTime';

import { Container } from './styles';

const Disembarkations = () => {
  const [trip, setTrip] = useState();
  const [passengers, setPassengers] = useState();

  const { socket } = useSocketContext();

  const { newErrorAlert } = useAlerts();

  const { request } = useApi();

  const tripId = getTripId();

  const attData = data => {
    setTrip(data);
    const pass = data.passengers.filter(e => e.status === 'running');

    const waitingPass = data.passengers.filter(e => e.status === 'waiting');

    setPassengers(pass);
    if (pass.length < 1 && waitingPass.length < 1) {
      socket.emit('finish');
    }
  };

  useEffect(() => {
    socket.on('passenger-landing', () => {
      request(
        () => getTripById(tripId),
        response => attData(response.data)
      );
    });
    socket.on('passenger-cancel', () => {
      request(
        () => getTripById(tripId),
        response => attData(response.data)
      );
    });

    socket.on('finish', () => {
      setStatus('success');
      window.location.reload();
    });
    socket.on('no-trip', () => {
      setStatus('home');
      window.location.reload();
    });
  }, []);

  const passengerLanding = id => {
    socket.emit('passenger-landing', { id });
  };

  const message = id => {
    // socket.emit('driver-arrived', { id });
    // newSuccessAlert('Você avisou ao passageiro que chegou :)');

    request(
      () => getPassengerById(id),
      response => {
        if (response.data.phone)
          window.open(`https://wa.me/${response.data.phone}`);
        else
          newErrorAlert('Ops! tivemos um problema com o número do passageiro');
      }
    );
  };

  useApiEffect(
    () => getTripById(tripId),
    response => attData(response.data)
  );

  return (
    <Container>
      <Background />
      <div className="wrapper">
        <NTitle label="Desembarque" color="white" backStyle="circle" />
        {trip && (
          <p className="info">
            {trip.cost.total} - {getCorrectTime(trip.time)}
          </p>
        )}
        {passengers && (
          <div className="embarkations-div">
            {passengers.map((item, i) => (
              <NCardPeople
                name={item.landingAddress.name}
                address={item.landingAddress.reference}
                people={item.passenger}
                status={item.status}
                cost={item.cost}
                onConfirm={() => message(item.passenger.id)}
                onMensagem={() => passengerLanding(item.passenger.id)}
                key={i}
              />
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};

export default Disembarkations;
