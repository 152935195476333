import dayjs from 'dayjs';
import { useState } from 'react';
import { ReactComponent as PinIcon } from '~/assets/icons/establishment_location.svg';
import userIcon from '~/assets/icons/individual.svg';
import { ReactComponent as Location } from '~/assets/icons/location.svg';
import { ReactComponent as UndoIcon } from '~/assets/icons/undo.svg';
import { useApi } from '~/hooks';
import { updateWaitingTrip } from '~/services/trips';
import { PriceFormatter } from '~/utils';
import { NButton } from '~/components';

const { Container } = require('./styles');

const NCardEstablishmentWaiting = ({
  driver,
  passenger,
  driverPhotoImage,
  status,
  address,
  cost,
  id,
  orderIsReady,
  handleReset,
  date = new Date(),
  ...props
}) => {
  const { request } = useApi();
  const [loading, setLoading] = useState(false);

  const handleUpdate = () => {
    setLoading(true);

    return request(
      () => {
        updateWaitingTrip(id, { orderIsReady: true });
      },
      () => {
        setLoading(false);
        handleReset();
      }
    );
  };

  return (
    <Container status={status} {...props}>
      <div className="es_card_header">
        <div className="es_card_image">
          <img src={driverPhotoImage ?? userIcon} alt="Profile" />
        </div>
        <div className="es_title">
          {status !== 'running' && (
            <p>
              {status === 'finished'
                ? dayjs(date).format('DD/MM/YYYY HH:mm')
                : 'Cancelada'}
            </p>
          )}
          <h3>{driver}</h3>
        </div>
        <PinIcon />
      </div>
      <div className="es_card_divider" />
      <div className="es_card_body">
        <div>
          <div className="es_card_status" />
          <p>
            {passenger} - {PriceFormatter.format(cost)}
          </p>
        </div>
        <UndoIcon />
      </div>
      <div className="es_card_footer">
        <Location />
        <p>{address}</p>
      </div>
      <div className="es_card_footer_2">
        <NButton
          label={!orderIsReady ? 'Pedido pronto' : 'Aguardando entregador'}
          className="button"
          color="primary"
          type="button"
          disabled={loading || orderIsReady}
          onClick={handleUpdate}
        />
      </div>
    </Container>
  );
};

export default NCardEstablishmentWaiting;
