import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { Home } from '~/pages';
import { AndroidPage, ApplePage, Install } from '~/pages/InstallPwa';
import {
  Disembarkation,
  Embarkation,
  Profile,
  Requests,
  Success,
  Times,
  Trips,
  Waiting,
} from '~/pages/Passenger';

import { setTripStorageId } from '~/repositories/trip';

import { useAlerts, useAuth, useSocketContext } from '~/hooks';
import DriverActive from '~/pages/Auth/Register/DriverActive';
import DriverAnalysis from '~/pages/Auth/Register/DriverAnalysis';
import Establishment from '~/pages/Establishment';
import { getStatus, setStatus } from '~/repositories/status';
import { getDomain } from '~/utils';

const Passenger = () => {
  const { state } = useLocation();

  const { socket } = useSocketContext();

  const { newErrorAlert } = useAlerts();

  const { user } = useAuth();

  const status = getStatus();

  useEffect(() => {
    socket.on('in-trip', data => {
      setTripStorageId(data.id);
      if (data.status === 'running' && status !== 'running') {
        setStatus('running');
        window.location.reload();
      } else if (data.status === 'waiting' && status !== 'waiting') {
        setStatus('waiting');
        window.location.reload();
      }
    });

    socket.on('create-trip', data => {
      setStatus('waiting');
      setTripStorageId(data.id);
      window.location.reload();
    });
    socket.on('join-trip', data => {
      setStatus('waiting');
      setTripStorageId(data.trip.id);
      window.location.reload();
    });
    socket.on('disconnect', () => {
      window.location.reload();
    });
    socket.on('time-out', () => {
      setStatus('home');
      window.location.reload();
    });
    socket.on('error', e => {
      console.log(e);
      newErrorAlert(
        'Algo deu errado, por favor tente novamente mais tarde',
        'socket'
      );
    });
  }, []);

  return (
    <Routes>
      {status === 'waiting' && <Route path="*" element={<Waiting />} exact />}
      {status === 'running' && <Route path="*" element={<Waiting />} exact />}
      {status === 'success' && <Route path="*" element={<Success />} exact />}
      {status !== 'waiting' && status !== 'running' && status !== 'success' && (
        <Route>
          <Route path={`/${getDomain()}/home`} element={<Home />} />
          <Route
            path={`/${getDomain()}/establishment`}
            element={<Establishment />}
          />

          <Route
            path={`/${getDomain()}/instalacao`}
            exact
            element={<Install />}
          />
          <Route
            path={`/${getDomain()}/instalacaoandroid`}
            exact
            element={<AndroidPage />}
          />
          <Route
            path={`/${getDomain()}/instalacaoios`}
            exact
            element={<ApplePage />}
          />
          <Route
            path={`/${getDomain()}/pedidos`}
            exact
            element={<Requests />}
          />

          <Route
            path={`/${getDomain()}/embarque`}
            element={<Embarkation />}
            exact
          />

          {state?.disembarkation && (
            <Route
              path={`/${getDomain()}/desembarque`}
              element={<Disembarkation />}
              exact
            />
          )}

          <Route
            path={`/${getDomain()}/desembarque/:tripId`}
            element={<Disembarkation />}
          />

          {state?.routeId && (
            <Route
              path={`/${getDomain()}/horarios`}
              element={<Times />}
              exact
            />
          )}

          <Route path={`/${getDomain()}/perfil`} element={<Profile />} exact />
          <Route
            path={`/${getDomain()}/perfil/phone`}
            element={<Profile.Phone />}
            exact
          />
          <Route
            path={`/${getDomain()}/perfil/email`}
            element={<Profile.Email />}
            exact
          />
          <Route
            path={`/${getDomain()}/cadastro/veiculo`}
            element={<Profile.FormCar />}
            exact
          />
          <Route
            path={`/${getDomain()}/cadastro/veiculo/analise`}
            element={<DriverAnalysis />}
            exact
          />
          <Route
            path={`/${getDomain()}/cadastro/veiculo/ativo`}
            element={<DriverActive />}
            exact
          />

          <Route
            path="*"
            element={user.establishments ? <Establishment /> : <Trips />}
            exact
          />
        </Route>
      )}
    </Routes>
  );
};

export default Passenger;
