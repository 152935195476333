import React from 'react';

import { AuthContext, AuthProvider } from './auth';
import { AppContext, AppProvider } from './app';
import { SocketContext, SocketProvider } from './socket';
import { AlertContext, AlertProvider } from './alert';

const ContextProvider = ({ children }) => (
  <AppProvider>
    <AuthProvider>
      <SocketProvider>
        <AlertProvider>{children}</AlertProvider>
      </SocketProvider>
    </AuthProvider>
  </AppProvider>
);

export { AuthContext, AppContext, SocketContext, AlertContext };

export default ContextProvider;
