import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.light_gray};

  display: flex;
  flex-direction: column;
  align-items: center;

  .div_top {
    display: flex;
    flex-direction: column;
    gap: 32px;
    z-index: 20;
    justify-content: center;
    align-items: center;

    .div_top_inner {
      display: flex;
      flex-direction: column;
      gap: 32px;
      > button {
        border-radius: 99999px;
        width: 100%;
      }
    }

    p {
      color: white;
    }

    h1 {
      color: white;
      font-size: 20px;
    }
    width: 100vw;

    margin-top: 40px;
    margin-bottom: 60px;
  }

  .div_center {
    display: grid;
    width: 100%;
    height: 100%;
    flex: 1;
    z-index: 10;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    position: relative;

    .divider {
      width: 6px;
      height: 180px;
      border-radius: 100px;
      position: absolute;
      background-color: #ffffff;
      top: 50%;
      left: 25%;
    }

    .divider_2 {
      width: 6px;
      height: 180px;
      border-radius: 100px;
      position: absolute;
      background-color: #ffffff;
      top: 50%;
      left: 50%;
    }

    .divider_3 {
      width: 6px;
      height: 180px;
      border-radius: 100px;
      position: absolute;
      background-color: #ffffff;
      top: 50%;
      left: 75%;
    }

    .in_progress {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      .empty_state {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        p {
          font-size: 25px;
        }
      }
    }

    .was_finished {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      .empty_state {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        p {
          font-size: 25px;
        }
      }
    }

    .was_canceled {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      .empty_state {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        p {
          font-size: 25px;
        }
      }
    }

    .is_waiting {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;

      .empty_state {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        p {
          font-size: 25px;
        }
      }
    }

    justify-content: center;

    h2 {
      color: #fff;
      font-size: 25px;
      margin-bottom: 37px;
    }
  }

  .div_center_mobile {
    display: none;
    width: 100%;
    height: 100%;
    flex: 1;
    z-index: 10;
    flex-direction: column;
    gap: 80px;
    position: relative;

    .selector_wrapper {
      width: 100%;
      padding: 0 20px;
    }

    .selector {
      width: 100%;
      margin: 0 auto;
      /* padding: 0 20px; */

      .label {
        color: #fff;
      }

      > div {
        background-color: #fff;
        .option,
        svg {
          color: #4d4d4d !important;
        }
      }
    }

    .in_progress {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto 30px;
      gap: 20px;

      .empty_state {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        p {
          font-size: 25px;
        }
      }
    }

    .was_finished {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto 30px;
      gap: 20px;

      .empty_state {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        p {
          font-size: 25px;
        }
      }
    }

    .was_canceled {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 0 auto 30px;
      gap: 20px;

      .empty_state {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;

        p {
          font-size: 25px;
        }
      }
    }
  }

  .card {
    z-index: 10;
    margin-bottom: 20px;
  }

  > .img {
    position: absolute;
    bottom: 50px;
    z-index: 0;
  }

  > .text_warning {
    z-index: 0;
    text-align: center;
    color: white;
  }

  .div_next {
    display: flex;
    align-items: center;
    justify-content: center;

    color: ${({ theme }) => theme.colors.primary};

    margin-bottom: 20px;

    gap: 15px;

    .advanced {
      font-size: 30px;
    }
  }

  @media (max-width: 735px) {
    .div_top {
      margin: 40px 0;
      padding: 0 20px;
      text-align: center;
    }

    .div_center {
      display: none;
    }

    .div_center_mobile {
      display: flex;
    }
  }
`;
