import styled, { css } from 'styled-components';

export const ButtonContainer = styled.button`
  width: 100%;
  height: 3.25rem;
  margin-bottom: 0.5rem;

  border-radius: 10px;
  font-size: 0.875rem;
  font-weight: bold;

  ${({ theme, variant }) => css`
    color: ${variant === 'primary' ? theme.colors.white : theme.colors.primary};
    background: ${variant === 'primary' ? theme.colors.primary : 'transparent'};
    border: 2px solid
      ${variant === 'primary' ? 'transparent' : theme.colors.primary};
  `};

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
`;
