import React, { useState } from 'react';

import { useOutsideClick } from '~/hooks';

import timelineIcon from '~/assets/icons/timeline.svg';
import badIcon from '~/assets/icons/bad_face.png';
import happyIcon from '~/assets/icons/happy_face.png';
import neutralIcon from '~/assets/icons/neutral_face.png';

import { Container, Button, PassengersList } from './styles';

const NCardHistoryDriver = ({
  date,
  time,
  total,
  profit,
  origin,
  passengers = [],
  destiny,
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const container = useOutsideClick(() => setOpen(false));

  const rateIcon = rate => {
    switch (rate) {
      case 1:
        return badIcon;
      case 3:
        return happyIcon;
      default:
        return neutralIcon;
    }
  };

  return (
    <Container ref={container} {...rest}>
      <div className="header">
        <div className="title">
          <p className="title_text">
            {date} - {time} - {total}
          </p>
        </div>

        <Button open={open} onClick={() => setOpen(!open)}>
          <div className="horizontal" />
          <div className="vertical" />
        </Button>
      </div>
      <p className="label">
        {origin} - {destiny}
      </p>
      <PassengersList open={open} qt={passengers.length}>
        {passengers.map((item, index) => (
          <div className="div_key" key={index}>
            <strong>
              {item.passenger.name} - {item.cost}
            </strong>

            <div className="route">
              <img src={timelineIcon} alt="timeline-icon" />
              <div className="route-column">
                <p className="place">{item.boardingAddress}</p>
                <p className="place">{item.landingAddress}</p>
              </div>

              <img
                src={rateIcon(item.rating)}
                alt="rate-icon"
                className="rate-icon"
              />
            </div>
          </div>
        ))}
      </PassengersList>
    </Container>
  );
};

export default NCardHistoryDriver;
