import React from 'react';

import { Container } from './styles';

const NCardItem = ({ label, small, smallW, primary, ...rest }) => (
  <Container small={small} primary={primary} smallW={smallW} {...rest}>
    {label && <p className="label">{label}</p>}
  </Container>
);

export default NCardItem;
