import { useApp } from '~/hooks';

import {
  ButtonCloseModal,
  ButtonSpeed,
  Modal,
  ModalOverlay,
  PriceTrip,
  TitleModal,
} from './styles';

const NModalWaiting = ({ isOpen, onClose, handleIncreaseSeats }) => {
  if (!isOpen) return null;

  const { app } = useApp();

  return (
    <ModalOverlay>
      <Modal>
        <TitleModal>
          Aumente o valor para acelerar a confirmação do seu chamado...
        </TitleModal>
        <PriceTrip>R$ 40,00</PriceTrip>
        <ButtonSpeed
          onClick={handleIncreaseSeats}
          buttonColorSpeed={app.colors.primary}
        >
          Aumentar
        </ButtonSpeed>
        <ButtonCloseModal onClick={onClose}>Aguarda</ButtonCloseModal>
      </Modal>
    </ModalOverlay>
  );
};

export default NModalWaiting;
