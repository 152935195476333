import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    transition: all 0.3s;

    font: 400 16px Comfortaa, sans-serif;
  }

  html, * {
    font-size: 16px;

    @media (max-width: 768px) {
      font-size: 14px;
    }

    @media (max-width: 480px) {
      font-size: 13px;
    }
     @media (max-width: 268px) {
      font-size: 11px;
    }
  }

  svg .primary {
    fill: ${({ theme }) => theme.colors.primary};
  }

  svg .primary-stroke {
    stroke: ${({ theme }) => theme.colors.primary};
  }

  h1, h2, p, label, button, a {
    color: ${({ theme }) => theme.colors.dark};
  }

  button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    outline: none;
    -webkit-tap-highlight-color: transparent;

    transition: opacity .2s;

    &:active {
      opacity: 0.7;
    }
  }

  fieldset {
    border: 0;
  }

  input {
    outline: none;
  }

  .pac-container {
    padding: 50px 0 0;
    z-index: 10;

    border-radius: 20px;

    transform: translateY(-50px);
  }
`;
