import styled from 'styled-components';

import NModal from '../NModal';

export const Container = styled(NModal)`
  .body {
    padding: 26px 0 31px;

    .icon {
      color: ${({ theme }) => theme.colors.primary};
    }

    .title {
      margin: 10px 0 24px;

      max-width: 90%;

      text-align: center;
      color: ${({ theme }) => theme.colors.primary};
      font-size: 25px;
    }

    > .label {
      font-size: 20px;
      max-width: 90%;
      text-align: center;
    }

    .confirm-button {
      margin: 25px 0 0px;
    }
  }
`;
