import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  font-family: 'Comfortaa', cursive;
  color: #4d4d4d;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
`;

export const Buttonback = styled.button`
  border: none;
  margin-top: 6%;
  margin-right: 86%;
`;
export const IconApple = styled.div`
  margin: 30% 0% 5% 0%;
  @media only screen and (max-device-height: 680px) {
    margin: 12% 0% 5% 0%;
  }
`;
export const Title = styled.h1`
  width: 100%;
  font-size: 20px;
  font-weight: 400;
  text-align: left;

  font-family: 'Comfortaa', cursive;
  color: #4d4d4d;
  padding: 10%;
  display: flex;
`;
export const ShareIcon = styled.img`
  width: 50px;
  height: 50px;
`;
export const Title2 = styled.h1`
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  padding: 17%;
  text-align: center;
  @media only screen and (max-device-height: 680px) {
    padding: 10%;
  }
`;

export const SkipButton = styled.a`
  font-family: 'Comfortaa';
  font-size: 20px;
  font-weight: 700;
`;

export const DoneButton = styled.a`
  background: #944bbb;
  border-radius: 10px;
  width: 80%;
  height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 15%;
  padding-right: 15%;
  margin-bottom: 5%;
  margin-top: 16%;
  font-family: 'Comfortaa';
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  color: white;
  text-decoration: none;
`;

export const HeadTitleContainer = styled.div`
  margin-top: 15%;
  margin-bottom: 25%;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const HeadTitle = styled.h1`
  font-family: 'Comfortaa';
  font-size: 27px;
  font-weight: 400;
`;
