import styled from 'styled-components';

export const Container = styled.div`
  @media only screen and (max-width: 1125px) and (max-height: 2001px) {
    color: white;
    visibility: visible;
    text-decoration: none;
    text-decoration-style: none;
    display: block;
    button {
      color: white;
    }
  }
  display: none;
`;

export const ApplePageWrapper = styled.div`
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;
