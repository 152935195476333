import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px 0 11px;
  ${({ theme }) => theme.styles.cardWidth}
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: white;
  color: ${({ theme }) => theme.colors.dark};
  .cheguei {
    ${({ theme }) => theme.styles.itemWidth} !important;
    height: 45px;
    border-radius: 20px;
    margin-top: 10px;
  }
  > .nameContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    .icon {
      height: 25px;
      width: 80px;
    }
    .button_div {
      display: flex;
      flex-direction: column;
      text-align: start;
      text-shadow: 0;
      align-items: start;
    }
    .label,
    .labelAdress {
      overflow: hidden;
    }
    .labelAdress {
      font-size: 14px;
      margin-top: 2px;
      color: ${({ theme }) => theme.colors.gray};
    }
    .icon_navigate {
      margin: 0 10px 0 auto;
      color: ${({ theme }) => theme.colors.primary};
      width: 100px;
    }
    width: 100%;
    margin-bottom: 15px;
    margin-top: 5px;
    padding: 0px 0px 0px 20px;
  }
  > .peopleDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 11px;
  }
`;
