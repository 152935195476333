import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .subtitle {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 12px;
  }

  .trip-finished {
    max-width: 50%;
  }

  .money_div {
    display: flex;
    align-items: center;
    margin: 24px 0;

    > .money_label {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 32px;
    }
  }

  .comment {
    border: 1px solid #000;
    width: 80%;
    height: 150px;
    margin-bottom: 24px;
    max-width: 490px;
    border-radius: 12px;
    padding: 16px;
  }
`;

export const Wrapper = styled.div`
  ${({ theme }) => theme.styles.itemWidth}
  height: 69px;
  border-radius: 15px;
  background: ${({ theme }) => theme.colors.dark};
  position: relative;

  margin: 5px 0 36px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    font-size: 16px;
    color: white;
  }

  > .icon {
    position: absolute;
    right: 20px;
    color: white;
  }
`;

export const RateContainer = styled.div`
  display: flex;
  width: 50%;
  max-width: 300px;
  justify-content: space-between;
  margin-bottom: 36px;

  button {
    filter: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rate-icon {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
  }
`;
