import { useNavigate } from 'react-router-dom';
import { getDomain } from '~/utils';
import Arrow8 from '../../../assets/icons/Arrow8.svg';
import ArrowBack from '../../../assets/icons/ArrowBack.svg';
import Android from '../../../assets/icons/Android.svg';
import Apple from '../../../assets/icons/Apple.svg';
import { Container, Title, DivArrow, ButtonLink } from './styles';

const Install = () => {
  const navigate = useNavigate();

  const linkIos = `http://weptek.app/${getDomain()}/instalacaoios`;
  const linkAndroid = `intent://weptek.app/${getDomain()}/instalacaoandroid#Intent;scheme=http;package=com.android.chrome;end`;
  return (
    <Container>
      <DivArrow
        type="button"
        onClick={() => navigate(`/${getDomain()}/`)}
        alt="Seta para voltar"
      >
        <img src={ArrowBack} alt="Seta para voltar" />
      </DivArrow>
      <Title>
        Qual é o seu <br /> dispositivo?
      </Title>

      <ButtonLink href={linkAndroid} target="_blank" rel="safari">
        <img src={Android} alt="Icone do Android" />
        Android
        <img src={Arrow8} alt="Seta" />
      </ButtonLink>

      <ButtonLink href={linkIos} target="_blank" rel="safari">
        <img src={Apple} alt="Icone da Apple" />
        Iphone
        <img src={Arrow8} alt="Seta" />
      </ButtonLink>

      {/* <Button
        type="button"
        onClick={() => navigate(`/${getDomain()}/instalacaoios`)}
      >
        <img src={Apple} alt="Icone da Apple" />
        Iphone
        <img src={Arrow8} alt="Seta" />
      </Button> */}
    </Container>
  );
};
export default Install;
