/* eslint-disable react/react-in-jsx-scope */
import { NButton } from '~/components';

import { Container, Wrapper } from './styles';

const ConfirmTripModal = ({
  active,
  actualTime,
  onConfirm = () => null,
  onClose = () => null,
}) => (
  <Container active={active}>
    <Wrapper>
      <p className="label">Confirmar viagem?</p>
      <NButton
        className="confirm-button"
        color="primary"
        disabled={actualTime === ''}
        onClick={onConfirm}
        label="Confirmar"
      />
      <NButton
        className="back-button"
        color="primary"
        onClick={onClose}
        label="Voltar"
      />
    </Wrapper>
  </Container>
);

export default ConfirmTripModal;
