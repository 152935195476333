/* eslint-disable react/react-in-jsx-scope */
import { NButton, NTimer } from '~/components';

import { Container, Wrapper } from './styles';

const NModalHours = ({
  visible,
  onConfirm = () => null,
  onClose = () => null,
  onTime = () => null,
  spots,
  time,
  times = [],
  ...rest
}) => {
  function chosed(actualTime) {
    let is;
    if (actualTime === time) {
      is = true;
    } else {
      is = false;
    }
    return is;
  }

  const timers = times.map((item, index) => (
    <NTimer
      key={index}
      label={`${item.slice(0, 5)}`}
      num={0}
      chosed={chosed(item)}
      onClick={() => onTime(item)}
      vagas={spots}
      className="timer"
    />
  ));

  return (
    <Container visible={visible} {...rest} onClose={onClose}>
      <p className="label">Selecione um novo horário</p>
      <Wrapper>
        {times.length > 0 && timers}
        {times.length < 1 && (
          <p className="text_warning">
            Todos os horários disponíveis já foram escolhidos
          </p>
        )}
      </Wrapper>
      <NButton
        className="confirm-button"
        color="primary"
        disabled={times.length < 1}
        onClick={onConfirm}
        label="Confirmar vaga"
      />
    </Container>
  );
};

export default NModalHours;
