/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { detectIos } from '~/utils/isIos';
import { getDomain } from '~/utils';
import ApplePage from '~/pages/InstallPwa/ApplePage';
import { ApplePageWrapper, Container } from './styles';
import NModalInstallPwa from '../NModalInstallPwa';

function NModalPwaLogin() {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [isInstallActive, setIsInstallActive] = useState(false);

  const isIos = detectIos();

  return (
    <Container>
      {openModal && (
        <NModalInstallPwa style={{ margin: 0 }} setOpenModal={setOpenModal} />
      )}
      <button
        type="button"
        onClick={() => {
          if (isIos) {
            setIsInstallActive(true);
          } else {
            navigate(`/${getDomain()}/instalacao`);
          }
        }}
      >
        Instalar
      </button>
      {isInstallActive && (
        <ApplePageWrapper>
          <ApplePage onBack={() => setIsInstallActive(false)} />
        </ApplePageWrapper>
      )}
    </Container>
  );
}

export default NModalPwaLogin;
